function _templateObject4() {
  const data = _taggedTemplateLiteral(["\nmutation SetSetupWithOutAck($id_old_order: bigint!, $ts_end: timestamptz, $id_new_order: bigint!, $gross: Int, $ts_start: timestamptz, $production_real: bigint, $production_final: bigint, $txt_order_notes: String) {\n  old_po: update_production_orders(where: {id_production_order: {_eq: $id_old_order}}, _set: {ts_end: $ts_end, status: 2, gross_production: $gross, production_real: $production_real, production_final: $production_final, txt_production_order_notes: $txt_order_notes}) {\n    returning {\n      id_production_order\n      gross_production\n    }\n    affected_rows\n  }\n  new_po: update_production_orders(where: {id_production_order: {_eq: $id_new_order}}, _set: {ts_start: $ts_start, ts_end: null, status: 1}) {\n    returning {\n      id_production_order\n    }\n    affected_rows\n  }\n}\n"]);

  _templateObject4 = function () {
    return data;
  };

  return data;
}

function _templateObject3() {
  const data = _taggedTemplateLiteral(["\n  mutation SetSetupNoNext($id_old_order: bigint!, $ts_end: timestamptz, $gross: Int, $production_real: bigint, $production_final: bigint, $txt_order_notes: String) {\n  old_po: update_production_orders(where: {id_production_order: {_eq: $id_old_order}}, _set: {ts_end: $ts_end, status: 2, gross_production: $gross, production_real: $production_real, production_final: $production_final, txt_production_order_notes: $txt_order_notes}) {\n    returning {\n      id_production_order\n      gross_production\n    }\n    affected_rows\n  }\n}\n"]);

  _templateObject3 = function () {
    return data;
  };

  return data;
}

function _templateObject2() {
  const data = _taggedTemplateLiteral(["\n  mutation SetDowntimeAckAndSetupNoNext($id_equipment_event: bigint!, $downtimereason: Int, $id_equipment_reason: Int, $txt: String, $id_old_order: bigint!, $ts_end: timestamptz, $production_real: bigint, $production_final: bigint, $txt_order_notes: String, $id_sub: Int) {\n  update_equipmentevents(where: {id_equipment_event: {_eq: $id_equipment_event}}, _set: {downtimereason: $downtimereason, id_equipment_reason: $id_equipment_reason, txt_downtime_notes: $txt, id_subcategory: $id_sub}) {\n    returning {\n      alert_level1\n      alert_level2\n      alert_level3\n      alert_level4\n      countervalue\n      downtimereason\n      id_counter\n      id_equipment_reason\n      id_subcategory\n      status\n      txt_downtime_notes\n    }\n    affected_rows\n  }\n  old_po: update_production_orders(where: {id_production_order: {_eq: $id_old_order}, ts_start: {}}, _set: {ts_end: $ts_end, status: 2, production_real: $production_real, production_final: $production_final, txt_production_order_notes: $txt_order_notes}) {\n    returning {\n      id_production_order\n    }\n    affected_rows\n  }\n}\n"]);

  _templateObject2 = function () {
    return data;
  };

  return data;
}

function _templateObject() {
  const data = _taggedTemplateLiteral(["\n  mutation SetDowntimeAckAndSetup($id_equipment_event: bigint!, $downtimereason: Int, $id_equipment_reason: Int, $txt: String, $id_old_order: bigint!, $ts_end: timestamptz, $production_real: bigint, $production_final: bigint, $txt_order_notes: String, $id_new_order: bigint!, $ts_start: timestamptz, $id_sub: Int) {\n  update_equipmentevents(where: {id_equipment_event: {_eq: $id_equipment_event}}, _set: {downtimereason: $downtimereason, id_equipment_reason: $id_equipment_reason, txt_downtime_notes: $txt, id_subcategory: $id_sub}) {\n    returning {\n      alert_level1\n      alert_level2\n      alert_level3\n      alert_level4\n      countervalue\n      downtimereason\n      id_counter\n      id_equipment_reason\n      id_subcategory\n      status\n      txt_downtime_notes\n    }\n    affected_rows\n  }\n  old_po: update_production_orders(where: {id_production_order: {_eq: $id_old_order}}, _set: {ts_end: $ts_end, status: 2, production_real: $production_real, production_final: $production_final, txt_production_order_notes: $txt_order_notes}) {\n    returning {\n      id_production_order\n    }\n    affected_rows\n  }\n  new_po: update_production_orders(where: {id_production_order: {_eq: $id_new_order}}, _set: {ts_start: $ts_start, ts_end: null, status: 1}) {\n    returning {\n      id_production_order\n    }\n    affected_rows\n  }\n}\n"]);

  _templateObject = function () {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

/* eslint-disable max-len */
import gql from 'graphql-tag';
export const SET_SETUP_ACK = gql(_templateObject());
export const SET_SETUP_ACK_NO_NEXT = gql(_templateObject2());
export const SET_SETUP_NO_NEXT = gql(_templateObject3());
export const SET_SETUP = gql(_templateObject4());