function _templateObject2() {
  const data = _taggedTemplateLiteral(["\n  font-size: 20px;\n  color: #000;\n"]);

  _templateObject2 = function () {
    return data;
  };

  return data;
}

function _templateObject() {
  const data = _taggedTemplateLiteral(["\n  width: 100vw;\n  height: 100vh;\n  display: flex;\n  flex-direction: column;\n  justify-content: start;\n  align-items: center;\n  .container {\n    margin-top: 20px;\n    width: 100%;\n    height: 300px;\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n    align-items: center;\n  }\n  ul {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: flex-start;\n  }\n  li {\n    width: 100%;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 10px;\n    border-top: 1px solid #bdbdbd;\n    font-weight: bold;\n  }\n  li:last-child {\n    border-bottom: 1px solid #bdbdbd;\n  }\n"]);

  _templateObject = function () {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

import styled from 'styled-components';
export const Container = styled.div.withConfig({
  displayName: "Container",
  componentId: "up82fw-0"
})(_templateObject());
export const Title = styled.h1.withConfig({
  displayName: "Title",
  componentId: "up82fw-1"
})(_templateObject2());