function _templateObject6() {
  const data = _taggedTemplateLiteral(["\n  font-size: 19px;\n  color: ", ";\n"]);

  _templateObject6 = function () {
    return data;
  };

  return data;
}

function _templateObject5() {
  const data = _taggedTemplateLiteral(["\n  width: 40px;\n  height: 30px;\n  border: none;\n  background: #fff;\n  position: absolute;\n  top: 10px;\n  left: 20px;\n  z-index: 99;\n"]);

  _templateObject5 = function () {
    return data;
  };

  return data;
}

function _templateObject4() {
  const data = _taggedTemplateLiteral(["\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 50px;\n  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-weight: bold;\n  font-size: 16px;\n  text-transform: uppercase;\n  background: #fff;\n"]);

  _templateObject4 = function () {
    return data;
  };

  return data;
}

function _templateObject3() {
  const data = _taggedTemplateLiteral(["\n  width: 100%;\n  height: 100px;\n  display: flex;\n  justify-content: ", ";\n  align-items: center;\n  margin-top: 40px;\n\n  .btn-cancel {\n    width: 130px;\n    height: 50px;\n    background: #fff;\n    color: #333333;\n    border: none;\n    margin-right: 15px;\n    font-style: normal;\n    font-weight: bold;\n    font-size: 18px;\n    line-height: 30px;\n    text-transform: uppercase;\n    outline: none;\n  }\n  .btn-submit {\n    width: 120px;\n    height: 50px;\n    background: #2F80ED;\n    border-radius: 10px;\n    font-style: normal;\n    font-weight: bold;\n    font-size: 18px;\n    line-height: 30px;\n    border: none;\n    color: #fff;\n    margin-right: ", "px;\n    text-transform: uppercase;\n    outline: none;\n  }\n"]);

  _templateObject3 = function () {
    return data;
  };

  return data;
}

function _templateObject2() {
  const data = _taggedTemplateLiteral(["\n  width: 100%;\n  height: 80px;\n  margin-top: 60px;\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n\n  .block {\n    width: 30%;\n    height: 50px;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n\n    .time {\n      font-weight: bold;\n      color: #000;\n      font-size: 16px;\n      span { font-size: 10px; }\n    }\n    .description {\n      padding-top: 4px;\n      font-size: 16px;\n    }\n  }\n"]);

  _templateObject2 = function () {
    return data;
  };

  return data;
}

function _templateObject() {
  const data = _taggedTemplateLiteral(["\n  width: 315px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n"]);

  _templateObject = function () {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

import styled from 'styled-components';
export const Container = styled.div.withConfig({
  displayName: "Container",
  componentId: "sc-14isz7-0"
})(_templateObject());
export const Info = styled.div.withConfig({
  displayName: "Info",
  componentId: "sc-14isz7-1"
})(_templateObject2());
export const ButtonContainer = styled.div.withConfig({
  displayName: "ButtonContainer",
  componentId: "sc-14isz7-2"
})(_templateObject3(), props => props.only ? 'center' : 'flex-end', props => props.only ? 0 : 20);
export const ModalHeader = styled.div.withConfig({
  displayName: "ModalHeader",
  componentId: "sc-14isz7-3"
})(_templateObject4());
export const ButtonClose = styled.button.withConfig({
  displayName: "ButtonClose",
  componentId: "sc-14isz7-4"
})(_templateObject5());
export const ModalText = styled.p.withConfig({
  displayName: "ModalText",
  componentId: "sc-14isz7-5"
})(_templateObject6(), props => props.color ? props.color : '#000');