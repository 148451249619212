import React from 'react';
import { Title, Text, Button, Icon, Group, Spacer, Grid, Row, Col } from "../..";
import { Exchange } from "../../assets/icons";
import colors from "../../colors";
import { capitalize } from "../../../utils/helpers";

const PoHeader = ({
  operator,
  data,
  completed,
  language,
  onClick,
  showChange
}) => React.createElement(Grid, {
  fluid: true
}, React.createElement(Row, {
  between: "xs"
}, React.createElement(Col, {
  xs: 6
}, React.createElement(Group, {
  isVerticalCentered: true,
  spaceSize: 10
}, React.createElement(Title, {
  isLarge: true,
  isBold: true,
  hasNoMarginBottom: true
}, language.job, " ", data[0].id_order), !showChange ? React.createElement(Button, {
  onClick: onClick,
  isBare: true
}, React.createElement(Icon, null, React.createElement(Exchange, null))) : ''), React.createElement(Spacer, {
  isVertical: true,
  size: 5
}), React.createElement(Text, {
  color: colors.blue
}, completed ? language.completed : language.inProduction)), React.createElement(Col, {
  xs: 6
}, React.createElement("div", {
  style: {
    textAlign: 'right'
  }
}, React.createElement(Text, {
  isMedium: true
}, " ", data[0].client, " "), React.createElement(Spacer, {
  isVertical: true,
  size: 5
}), React.createElement(Text, {
  isMedium: true
}, " ", data[0].product, " ")))), React.createElement(Spacer, {
  isLarge: true,
  isVertical: true
}), React.createElement(Row, null, React.createElement(Col, {
  xs: 12
}, React.createElement(Group, {
  isVerticalCentered: true,
  spaceSize: 10
}, React.createElement(Text, null, language.operator, " ", React.createElement("b", null, capitalize(operator)))))));

export default PoHeader;