function _templateObject3() {
  const data = _taggedTemplateLiteral(["\n  position: relative;\n  display: block;\n  padding-left: 20px;\n  padding-bottom: 50px;\n  &:after {\n    content: \"\";\n    position: absolute;\n    top: 4px;\n    left: 0;\n    border-left: 2px ", " ", ";\n    width: 2px;\n    height: 100%;\n  }\n  &:before {\n    content: \"\";\n    position: absolute;\n    top: 4px;\n    left: -4.6px;\n    border-radius: 100%;\n    width: 12px;\n    height: 12px;\n    background-color: ", ";\n  }\n  &:last-child {\n    padding-bottom: 0;\n    &:after {\n      display: none;\n    }\n  }\n"]);

  _templateObject3 = function () {
    return data;
  };

  return data;
}

function _templateObject2() {
  const data = _taggedTemplateLiteral(["", ""]);

  _templateObject2 = function () {
    return data;
  };

  return data;
}

function _templateObject() {
  const data = _taggedTemplateLiteral([" \n  padding-left: 8px;\n"]);

  _templateObject = function () {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

/* eslint-disable react/no-array-index-key */
import React from 'react';
import styled, { css } from 'styled-components';
import { Text, Button, Icon, Group, Fill, Spacer, Grid, Row, Col } from "../..";
import { Pencil } from "../../assets/icons";
import colors from "../../colors";
import moment from 'moment';
import { getTotalElapsedTime } from "../../../utils/helpers";
import { MdSettings } from 'react-icons/md';
import { getLanguage, getEnterprise } from "../../../utils/storage";
import { navigate } from '@reach/router';
const Container = styled.ul.withConfig({
  displayName: "Container",
  componentId: "sc-1gs33ds-0"
})(_templateObject());
const Item = styled.li.withConfig({
  displayName: "Item",
  componentId: "sc-1gs33ds-1"
})(_templateObject2(), ({
  isMissing
}) => css(_templateObject3(), isMissing ? 'dashed' : 'solid', isMissing ? colors.blue : colors.gray, isMissing ? colors.blue : colors.gray));

const index = ({
  isMissing,
  data,
  eventsData,
  currentShift,
  idEquip
}) => {
  const language = JSON.parse(getLanguage());
  let idEnterprise = Number(getEnterprise());
  const machineEvents = idEquip === '277' || idEquip === '280';

  if (data) {
    return React.createElement(Container, null, data && data.map(item => React.createElement(Item, {
      key: item.id_equipment_event,
      isMissing: isMissing
    }, React.createElement(Grid, {
      fluid: true
    }, React.createElement(Row, null, React.createElement(Col, {
      size: 70
    }, React.createElement(Text, {
      color: colors.blue
    }, moment(item.mints).format('DD/MM')), React.createElement(Text, {
      color: colors.blue,
      isBold: true
    }, moment(item.mints).format('HH:mm'), ' ')), React.createElement(Col, {
      xs: true
    }, React.createElement(Group, {
      isFullWidth: true,
      isVerticalCentered: true
    }, React.createElement(Text, {
      isBold: true
    }, " ", machineEvents ? item === null || item === void 0 ? void 0 : item.cd_equipment : '', "  ", language.pendingEvent, " "), React.createElement(Fill, null), React.createElement(Button, {
      isBare: true,
      onClick: () => {
        let data = {
          mints: item === null || item === void 0 ? void 0 : item.mints,
          maxts: item === null || item === void 0 ? void 0 : item.maxts,
          stagetime: item === null || item === void 0 ? void 0 : item.stagetime,
          id_equipment_event: item === null || item === void 0 ? void 0 : item.id_equipment_event,
          id_equipment: item === null || item === void 0 ? void 0 : item.id_equipment,
          cd_equipment: item === null || item === void 0 ? void 0 : item.cd_equipment
        };
        navigate("".concat(process.env.baseURL, "events/").concat(idEquip, "/ack"), {
          state: {
            eventData: data
          }
        });
      }
    }, React.createElement(Icon, {
      size: 18
    }, React.createElement(Pencil, null)))), React.createElement(Spacer, {
      isVertical: true,
      size: 4
    }), React.createElement(Text, null, moment(item.mints).format('DD/MM'), " ", language.at, ' ', moment(item.mints).format('HH:mm'), React.createElement("small", null, " \u25CF "), item.maxts !== null ? "".concat(moment(item.maxts).format('DD/MM'), " ").concat(language.at, " ").concat(moment(item.maxts).format('HH:mm')) : '', "\xA0 [", getTotalElapsedTime(item.stagetime, 'seconds'), "]"))), React.createElement(Spacer, {
      isVertical: true,
      size: 15
    }), React.createElement(Row, null, React.createElement(Col, {
      size: 70
    }))))));
  }

  return React.createElement(Container, null, eventsData && eventsData.map((item, index) => {
    var _item$equipment_type;

    return React.createElement(Item, {
      key: index
    }, React.createElement(Grid, {
      fluid: true
    }, React.createElement(Row, null, React.createElement(Col, {
      size: 70
    }, React.createElement(Text, {
      color: colors.gray
    }, moment(item.ts_event).format('DD/MM')), React.createElement(Text, {
      color: colors.gray,
      isBold: true
    }, moment(item.ts_event).format('HH:mm'), ' ')), React.createElement(Col, {
      xs: true
    }, React.createElement(Group, {
      isFullWidth: true,
      isVerticalCentered: true
    }, item.change_over ? React.createElement(Text, {
      isBold: true
    }, React.createElement(MdSettings, {
      size: 19
    }), " ", language.setup, ' ') : React.createElement(Text, {
      isBold: true
    }, (item === null || item === void 0 ? void 0 : (_item$equipment_type = item.equipment_type) === null || _item$equipment_type === void 0 ? void 0 : _item$equipment_type.nm_equipment_type) ? item.equipment_type.nm_equipment_type + ', ' : '', (item === null || item === void 0 ? void 0 : item.cd_machine) ? item.cd_machine + ', ' : '', item.event_type === 'manual event' ? "Manual Event - ".concat(item.txt_downtime_reason, " - ").concat((item === null || item === void 0 ? void 0 : item.txt_subcategory) || '') : '', item.event_type === 'job end' ? "Job ".concat(item.id_order, " End") : '', item.event_type === 'job start' ? "Job ".concat(item.id_order, " Start") : '', item.event_type === 'downtime' ? "".concat(item.txt_downtime_reason, " - ").concat((item === null || item === void 0 ? void 0 : item.txt_subcategory) || '') : ''), React.createElement(Fill, null), item.cd_shift == currentShift ? React.createElement(Button, {
      isBare: true,
      onClick: () => {
        let machine = item === null || item === void 0 ? void 0 : item.cd_machine;
        let downtime = {
          reason: item.downtimereason,
          label: "".concat(item.nm_downtime_reason, " - ").concat(item.txt_downtime_reason),
          subCategory: item.id_subcategory,
          subLabel: "".concat(item.nm_subcategory, " - ").concat(item.txt_subcategory)
        };

        if (item.equipment_type) {
          machine = item.equipment_type.nm_equipment_type;
        }

        if (!item.id_subcategory) downtime.subLabel = '';
        let data = {
          mints: item.ts_event,
          maxts: item.ts_end,
          stagetime: item.stagetime,
          id_equipment_event: item.id_equipment_event,
          downtime_reason: downtime,
          cd_machine: machine,
          notes: item.txt_downtime_notes,
          id_machine: item.id_equipment_reason,
          type: item.event_type
        };
        navigate("".concat(process.env.baseURL, "events/").concat(idEquip, "/edit"), {
          state: {
            eventData: data,
            idEnterprise
          }
        });
      }
    }, React.createElement(Icon, {
      size: 18
    }, React.createElement(Pencil, null))) : ''), React.createElement(Spacer, {
      isVertical: true,
      size: 4
    }), React.createElement(Text, null, moment(item.ts_event).format('DD/MM'), " ", language.at, ' ', moment(item.ts_event).format('HH:mm'), React.createElement("small", null, " \u25CF "), item.ts_end !== null ? "".concat(moment(item.ts_end).format('DD/MM'), " ").concat(language.at, " ").concat(moment(item.ts_end).format('HH:mm')) : '', "\xA0", item.stagetime ? "[".concat(getTotalElapsedTime(item.stagetime, 'seconds'), "]") : ''))), React.createElement(Spacer, {
      isVertical: true,
      size: 15
    }), React.createElement(Row, null, React.createElement(Col, {
      size: 70
    }), React.createElement(Col, {
      xs: true
    }, React.createElement(Text, {
      color: colors.gray
    }, item.txt_downtime_notes || '')))));
  }));
};

export default index;