import moment from 'moment';
/**
 * @function
 * @description Check if user has access on operator app
 * @param {Object} roles
 * Return - Boolean
 */

export const checkAccess = roles => {
  if (roles.site_operator && roles.site_operator.length) return true;
  if (roles.area_operator && roles.area_operator.length) return true;
  if (roles.line_operator && roles.line_operator.length) return true;
  if (roles.machine_operator && roles.machine_operator.length) return true;
  return false;
};
/**
 * @function
 * @description Check if user has access on site tab
 * @param {Object} roles
 * Return - Boolean
 */

export const checkSiteAccess = roles => {
  let access = {
    site: false,
    quantity: 0
  };

  if (roles) {
    roles = JSON.parse(roles);

    if (roles.site_operator) {
      access.site = true;
      access.quantity = roles.site_operator.length;
    }
  }

  return access;
};
/**
 * @function
 * @description Return capitalized string
 * @param {String} s
 * Return - String
 */

export const capitalize = s => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};
/**
 * @function
 * @description Return if a number is invalid
 * @param {*} number
 * Return - Boolean
 */

export function isInvalidNumber(number) {
  return isNaN(number) || !isFinite(number) || number === Infinity || number === 'Infinity' || number === -Infinity || number === '-Infinity' || number === NaN || number === 'NaN' || number === '∞' || number === '-∞' || number === undefined || number === 'undefined' || number === null || number === 'null';
}
;
export function isZeroOrInvalid(number) {
  return isNaN(number) || !isFinite(number) || number === Infinity || number === 'Infinity' || number === -Infinity || number === '-Infinity' || number === NaN || number === 'NaN' || number === '∞' || number === '-∞' || number === undefined || number === 'undefined' || number === null || number === 'null' || number === 0 || typeof number === 'string';
}
;
export const fixNumber = number => {
  if (isInvalidNumber(number)) return number;
  /*if (float >= 0) {
    return number.toLocaleString(locale, {
      maximumFractionDigits: float,
      minimumFractionDigits: 0
    });
  }*/

  return new Intl.NumberFormat().format(number);
};
export function convertToDaysHoursNoSeconds(value, time) {
  //if (isInvalidNumber(value)) return '';
  let durationInSeconds = moment.duration(value, time);

  if (durationInSeconds.days() > 0) {
    return durationInSeconds.days() + 'd ' + moment.utc(durationInSeconds.valueOf()).format('HH:mm');
  }

  return moment.utc(durationInSeconds.valueOf()).format('HH:mm');
}
;
export function getTotalElapsedTime(value, time) {
  let durationInSeconds = moment.duration(value, time);
  let total = '';
  if (durationInSeconds.days() > 0) total = durationInSeconds.days() + 'd ';
  if (durationInSeconds.hours() > 0) total += durationInSeconds.hours() + 'h ';
  if (durationInSeconds.minutes() > 0) total += durationInSeconds.minutes() + 'm ';
  return total;
}
/**
 * @function
 * @description Return a percent of a determined time
 * @param {number} elapsedTime
 * @param {number} downtime
 * Return String
 */

export function getPercentHour(elapsedTime, downtime) {
  if (elapsedTime <= 60 || downtime === 0) return '0';
  let elapsedMinutes = Math.round(elapsedTime / 60);
  let percent = downtime * 100 / elapsedMinutes;
  return percent.toFixed(1);
}
;
export function getEquipmentName(id, object) {
  if (!object) return {
    cd_equipment: '',
    type: ''
  };
  let name = object.equipments.find(element => element.id_equipment == id);
  if (name === undefined) return {
    cd_equipment: '',
    type: object.type
  };
  name.type = object.type;
  return name;
}
;
export function isChangeOver(id, data) {
  let object = data.downtime_reasons.find(element => element.cd_downtime_reason == id);
  if (object === undefined) return false;
  return object.change_over;
}