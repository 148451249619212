import React from 'react';
import { MdRefresh } from 'react-icons/md';
import { Container, TextError, ButtonRefresh } from "./styles";
export default function Error({
  error,
  height,
  full
}) {
  return React.createElement(Container, {
    height: height,
    full: full
  }, React.createElement(TextError, {
    color: "#000"
  }, error));
}