function _templateObject6() {
  const data = _taggedTemplateLiteral(["\n  width: 100%;\n  height: 100px;\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n  margin-top: 110px;\n\n  .btn-cancel {\n    width: 130px;\n    height: 50px;\n    background: #fff;\n    color: #333333;\n    border: none;\n    margin-right: 15px;\n    font-style: normal;\n    font-weight: bold;\n    font-size: 18px;\n    line-height: 30px;\n    text-transform: uppercase;\n    outline: none;\n  }\n  .btn-submit {\n    width: 120px;\n    height: 50px;\n    background: #2F80ED;\n    border-radius: 10px;\n    font-style: normal;\n    font-weight: bold;\n    font-size: 18px;\n    line-height: 30px;\n    border: none;\n    color: #fff;\n    margin-right: 20px;\n    text-transform: uppercase;\n    outline: none;\n  }\n"]);

  _templateObject6 = function () {
    return data;
  };

  return data;
}

function _templateObject5() {
  const data = _taggedTemplateLiteral(["\n  outline: none;\n  margin-top: 20px;\n  width: 100%;\n  height: 100px;\n  border-top: 0;\n  border-left: 0;\n  border-right: 0;\n  border-bottom: 2px solid #BDBDBD;\n  background: #fff;\n  color: #828282;\n  resize: none;\n  overflow: hidden;\n  &:focus {\n    border-bottom: 2px solid #2F80ED;\n  }\n"]);

  _templateObject5 = function () {
    return data;
  };

  return data;
}

function _templateObject4() {
  const data = _taggedTemplateLiteral(["\n  outline: none;\n  width: 100%;\n  height: 34px;\n  border-top: 0;\n  border-left: 0;\n  border-right: 0;\n  border-bottom: 2px solid #BDBDBD;\n  margin-top: 20px;\n  background: #fff;\n  color: #828282;\n  &:focus {\n    border-bottom: 2px solid #2F80ED;\n  }\n"]);

  _templateObject4 = function () {
    return data;
  };

  return data;
}

function _templateObject3() {
  const data = _taggedTemplateLiteral(["\n  width: 90%;\n  height: 250px;\n  margin-left: 4%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n"]);

  _templateObject3 = function () {
    return data;
  };

  return data;
}

function _templateObject2() {
  const data = _taggedTemplateLiteral(["\n  width: 100%;\n  height: 80px;\n  margin-top: 30px;\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n\n  .block {\n    width: 30%;\n    height: 50px;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n\n    .time {\n      font-weight: bold;\n      color: #000;\n      font-size: 18px;\n      span { font-size: 10px; }\n    }\n    .description {\n      padding-top: 4px;\n      font-size: 16px;\n    }\n  }\n"]);

  _templateObject2 = function () {
    return data;
  };

  return data;
}

function _templateObject() {
  const data = _taggedTemplateLiteral(["\n  width: 420px;\n  display: flex;\n  flex-direction: column;\n  align-items: baseline;\n"]);

  _templateObject = function () {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

import styled from 'styled-components';
export const Container = styled.div.withConfig({
  displayName: "Container",
  componentId: "sc-1u06cdu-0"
})(_templateObject());
export const Info = styled.div.withConfig({
  displayName: "Info",
  componentId: "sc-1u06cdu-1"
})(_templateObject2());
export const Form = styled.div.withConfig({
  displayName: "Form",
  componentId: "sc-1u06cdu-2"
})(_templateObject3());
export const Select = styled.select.withConfig({
  displayName: "Select",
  componentId: "sc-1u06cdu-3"
})(_templateObject4());
export const Textarea = styled.textarea.withConfig({
  displayName: "Textarea",
  componentId: "sc-1u06cdu-4"
})(_templateObject5());
export const ButtonContainer = styled.div.withConfig({
  displayName: "ButtonContainer",
  componentId: "sc-1u06cdu-5"
})(_templateObject6());