import React from 'react';
import { Router } from '@reach/router';
import SignIn from "./components/SignIn";
import Recovery from "./components/Recovery";

const Routes = () => React.createElement(Router, null, React.createElement(SignIn, {
  path: "/"
}), React.createElement(Recovery, {
  path: "recovery"
}));

export default Routes;