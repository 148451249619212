import React, { useEffect, useState } from 'react';
import { auth } from "../../services/firebase";
import { navigate } from '@reach/router';
import { getToken } from "../../utils/storage";
export const SessionContext = React.createContext({});

const SessionManager = ({
  children
}) => {
  const [currentUser, updateCurrentUser] = useState({
    stsTokenManager: {
      accessToken: ''
    }
  });
  const [isReady, updateStatus] = useState(null);
  useEffect(() => {
    auth().onAuthStateChanged(currentState => {
      if (!currentState) navigate("".concat(process.env.baseURL, "auth"));

      if (currentState) {
        const firebaseToken = getToken();

        if (!firebaseToken) {
          auth().signOut();
          navigate("".concat(process.env.baseURL, "auth"));
        }

        updateCurrentUser(Object.assign({}, currentState.toJSON(), {
          state: auth().currentUser
        }));
        updateStatus(true);
      }
    });
  }, []);
  return React.createElement(SessionContext.Provider, {
    value: {
      session: currentUser,
      isReady
    }
  }, children);
};

export default SessionManager;