import React from "react";

var ChartBars = function ChartBars(props) {
  return React.createElement("svg", props, React.createElement("path", {
    d: "M12 4.2h2V14h-2zM8 0h2v14H8zM0 8h2v6H0zM4 2.8h2V14H4z"
  }));
};

ChartBars.defaultProps = {
  id: "Layer_1",
  xmlns: "http://www.w3.org/2000/svg",
  width: "14",
  height: "14",
  viewBox: "0 0 14 14"
};
export { ChartBars };

var Checkmark = function Checkmark(props) {
  return React.createElement("svg", props, React.createElement("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M5.59 10.58L1.42 6.41 0 7.82l5.59 5.59 12-12L16.18 0 5.59 10.58z"
  }));
};

Checkmark.defaultProps = {
  width: "18",
  height: "14",
  viewBox: "0 0 18 14",
  xmlns: "http://www.w3.org/2000/svg"
};
export { Checkmark };

var CheckmarkBadge = function CheckmarkBadge(props) {
  return React.createElement("svg", props, React.createElement("path", {
    d: "M80.7 38.4l-8.9-10.2L73 14.7l-13.2-3-7-11.7-12.5 5.4L27.9 0 21 11.7l-13.2 3L9 28.2 0 38.4l8.9 10.2-1.2 13.5 13.2 3 6.9 11.7 12.5-5.4 12.5 5.4L59.7 65 73 62l-1.2-13.5 8.9-10.1zM34.1 50.1L23.8 39.9l2.6-2.6 7.6 7.6 19.4-19.4 2.6 2.6-21.9 22z"
  }));
};

CheckmarkBadge.defaultProps = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "80.7",
  height: "76.7",
  viewBox: "0 0 80.7 76.7"
};
export { CheckmarkBadge };

var Clock = function Clock(props) {
  return React.createElement("svg", props, React.createElement("path", {
    d: "M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.6 0 12 0zm0 21.6c-5.3 0-9.6-4.3-9.6-9.6S6.7 2.4 12 2.4s9.6 4.3 9.6 9.6-4.3 9.6-9.6 9.6z"
  }), React.createElement("path", {
    d: "M12.6 6h-1.8v7.2l6.3 3.8.9-1.5-5.4-3.2V6z"
  }));
};

Clock.defaultProps = {
  id: "Layer_1",
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  viewBox: "0 0 24 24"
};
export { Clock };

var Exchange = function Exchange(props) {
  return React.createElement("svg", props, React.createElement("path", {
    d: "M7.01 9H0v2h7.01v3L11 10 7.01 6v3zm5.98-1V5H20V3h-7.01V0L9 4l3.99 4z"
  }));
};

Exchange.defaultProps = {
  width: "20",
  height: "14",
  viewBox: "0 0 20 14",
  xmlns: "http://www.w3.org/2000/svg"
};
export { Exchange };

var History = function History(props) {
  return React.createElement("svg", props, React.createElement("path", {
    d: "M12 .51a9 9 0 0 0-9 9H0l3.89 3.89.07.14L8 9.51H5c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42A8.954 8.954 0 0 0 12 18.51a9 9 0 0 0 0-18zm-1 5v5l4.28 2.54.72-1.21-3.5-2.08V5.51H11z"
  }));
};

History.defaultProps = {
  width: "21",
  height: "19",
  viewBox: "0 0 21 19",
  xmlns: "http://www.w3.org/2000/svg"
};
export { History };

var InfoBadge = function InfoBadge(props) {
  return React.createElement("svg", props, React.createElement("path", {
    d: "M44 21.49l-4.88-5.56.68-7.36-7.22-1.64L28.8.57 22 3.49 15.2.57l-3.78 6.36L4.2 8.55l.68 7.36L0 21.49l4.88 5.56-.68 7.38 7.22 1.64 3.78 6.36 6.8-2.94 6.8 2.92 3.78-6.36 7.22-1.64-.68-7.36L44 21.49zm-20 10h-4v-4h4v4zm0-8h-4v-12h4v12z"
  }));
};

InfoBadge.defaultProps = {
  width: "44",
  height: "43",
  viewBox: "0 0 44 43",
  xmlns: "http://www.w3.org/2000/svg"
};
export { InfoBadge };

var Times = function Times(props) {
  return React.createElement("svg", props, React.createElement("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M16.375 2.211L14.789.625 8.5 6.914 2.211.625.625 2.211 6.914 8.5.625 14.789l1.586 1.586L8.5 10.086l6.289 6.289 1.586-1.586L10.086 8.5l6.289-6.289z"
  }));
};

Times.defaultProps = {
  width: "17",
  height: "17",
  viewBox: "0 0 17 17",
  xmlns: "http://www.w3.org/2000/svg"
};
export { Times };

var Lock = function Lock(props) {
  return React.createElement("svg", props, React.createElement("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M14 7h-1V5c0-2.76-2.24-5-5-5S3 2.24 3 5v2H2C.9 7 0 7.9 0 9v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3-9H5V5.04C5 3.362 6.345 2 8 2s3 1.363 3 3.04V7z"
  }));
};

Lock.defaultProps = {
  width: "16",
  height: "21",
  viewBox: "0 0 16 21",
  xmlns: "http://www.w3.org/2000/svg"
};
export { Lock };

var MenuBars = function MenuBars(props) {
  return React.createElement("svg", props, React.createElement("path", {
    d: "M.5.125v6.75h18V.125H.5zM.5 9.125v6.75h18v-6.75H.5z"
  }));
};

MenuBars.defaultProps = {
  width: "19",
  height: "16",
  viewBox: "0 0 19 16",
  xmlns: "http://www.w3.org/2000/svg"
};
export { MenuBars };

var Pencil = function Pencil(props) {
  return React.createElement("svg", props, React.createElement("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M17.701 2.7a1.017 1.017 0 0 1 0 1.44l-1.87 1.87L12 2.178l1.87-1.87a1.017 1.017 0 0 1 1.44 0L17.701 2.7zM0 18.01v-3.798L11.202 3.01 15 6.808 3.798 18.01H0z"
  }));
};

Pencil.defaultProps = {
  width: "18",
  height: "19",
  viewBox: "0 0 18 19",
  xmlns: "http://www.w3.org/2000/svg"
};
export { Pencil };

var Plus = function Plus(props) {
  return React.createElement("svg", props, React.createElement("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M16.61 16.806v12.156h-4.053V16.806H.401v-4.052h12.156V.6h4.052v12.155h12.156v4.052H16.609z"
  }));
};

Plus.defaultProps = {
  width: "29",
  height: "29",
  viewBox: "0 0 29 29",
  xmlns: "http://www.w3.org/2000/svg"
};
export { Plus };

var SelectArrow = function SelectArrow(props) {
  return React.createElement("svg", props, React.createElement("path", {
    d: "M8 0L4 4 0 0h8z"
  }));
};

SelectArrow.defaultProps = {
  width: "8",
  height: "4",
  viewBox: "0 0 8 4",
  xmlns: "http://www.w3.org/2000/svg"
};
export { SelectArrow };