import { toast } from 'react-toastify';
const toastConfig = {
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true
};
export const NotificationError = msg => {
  toast.error(msg, toastConfig);
};
export const NotificationWarning = msg => {
  toast.warning(msg, toastConfig);
};
export const NotificationSuccess = msg => {
  toast.info(msg, toastConfig);
};
export const NotificationDefault = msg => {
  toast(msg, toastConfig);
};