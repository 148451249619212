import { auth } from "./firebase";
import { split, ApolloLink, Observable } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
const API_URL = process.env.apiUrl;
export const fetchGraphql = async (query, variables) => {
  let token = await auth().currentUser.getIdToken();
  let response = await fetch("https://".concat(API_URL), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'authorization': "Bearer ".concat(token)
    },
    body: JSON.stringify({
      query,
      variables
    })
  });
  return response === null || response === void 0 ? void 0 : response.json();
};
const httpLink = new HttpLink({
  uri: "https://".concat(API_URL)
});
const wsLink = new WebSocketLink({
  uri: "wss://".concat(API_URL),
  options: {
    lazy: true,
    reconnect: true,
    connectionParams: async () => {
      let token = await auth().currentUser.getIdToken();
      return {
        headers: {
          Authorization: token ? "Bearer ".concat(token) : ''
        }
      };
    }
  }
});
const link = split(({
  query
}) => {
  const definition = getMainDefinition(query);
  return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
}, wsLink, httpLink);

const request = async operation => {
  let token = await auth().currentUser.getIdToken();
  operation.setContext({
    headers: {
      authorization: token ? "Bearer ".concat(token) : ''
    }
  });
};

const requestLink = new ApolloLink((operation, forward) => new Observable(observer => {
  let handle;
  Promise.resolve(operation).then(oper => request(oper)).then(() => {
    handle = forward(operation).subscribe({
      next: observer.next.bind(observer),
      error: observer.error.bind(observer),
      complete: observer.complete.bind(observer)
    });
  }).catch(observer.error.bind(observer));
  return () => {
    if (handle) handle.unsubscribe();
  };
}));
const errorLink = onError(({
  graphQLErrors,
  networkError
}) => {
  if (graphQLErrors) {
    graphQLErrors.map(({
      message,
      extensions
    }) => {
      console.error("[GraphQL error]: Message: ".concat(message, ", Location: ").concat(extensions.code));
    });
  }

  if (networkError) {
    console.error("[Network error]: ".concat(networkError));
  }
});
const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore'
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  }
};
export const client = new ApolloClient({
  link: ApolloLink.from([requestLink, link, errorLink]),
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions
});