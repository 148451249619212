function _templateObject10() {
  const data = _taggedTemplateLiteral(["\n  background: ", ";\n  width: 100%;\n  height: 43px;\n  border-radius: 5px;\n  color: #fff;\n  border: none;\n  font-weight: bold;\n  outline: none;\n  text-transform: uppercase;\n"]);

  _templateObject10 = function () {
    return data;
  };

  return data;
}

function _templateObject9() {
  const data = _taggedTemplateLiteral(["\n  background: ", ";\n  width: 100%;\n  height: 43px;\n  border-radius: 5px;\n  color: #fff;\n  border: none;\n  font-weight: bold; \n  outline: none;\n"]);

  _templateObject9 = function () {
    return data;
  };

  return data;
}

function _templateObject8() {
  const data = _taggedTemplateLiteral(["\n  outline: none;\n  margin-top: 20px;\n  width: 100%;\n  height: 100px;\n  border-top: 0;\n  border-left: 0;\n  border-right: 0;\n  border-bottom: 2px solid #BDBDBD;\n  background: #fff;\n  color: #828282;\n  resize: none;\n  overflow: hidden;\n  &:focus {\n    border-bottom: 2px solid #2F80ED;\n  }\n"]);

  _templateObject8 = function () {
    return data;
  };

  return data;
}

function _templateObject7() {
  const data = _taggedTemplateLiteral(["\n  outline: none;\n  margin-top: 10px;\n  width: 100%;\n  height: 30px;\n  border-top: 0;\n  border-left: 0;\n  border-right: 0;\n  border-bottom: 2px solid #BDBDBD;\n  background: #fff;\n  color: #828282;\n  overflow: hidden;\n  &:focus {\n    border-bottom: 2px solid #2F80ED;\n  }\n"]);

  _templateObject7 = function () {
    return data;
  };

  return data;
}

function _templateObject6() {
  const data = _taggedTemplateLiteral(["\n  font-size: 19px;\n  color: ", ";\n"]);

  _templateObject6 = function () {
    return data;
  };

  return data;
}

function _templateObject5() {
  const data = _taggedTemplateLiteral(["\n  width: 40px;\n  height: 30px;\n  border: none;\n  background: #fff;\n  position: fixed;\n  top: 10px;\n  right: 20px;\n  z-index: 99;\n"]);

  _templateObject5 = function () {
    return data;
  };

  return data;
}

function _templateObject4() {
  const data = _taggedTemplateLiteral(["\n  width: 40px;\n  height: 30px;\n  border: none;\n  background: #fff;\n  position: fixed;\n  top: 10px;\n  left: 20px;\n  z-index: 99;\n"]);

  _templateObject4 = function () {
    return data;
  };

  return data;
}

function _templateObject3() {
  const data = _taggedTemplateLiteral(["\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 50px;\n  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-weight: bold;\n  font-size: 16px;\n  text-transform: uppercase;\n  background: #fff;\n"]);

  _templateObject3 = function () {
    return data;
  };

  return data;
}

function _templateObject2() {
  const data = _taggedTemplateLiteral(["\n   background: #fff;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: center;\n  position: relative;\n  animation: fadein 0.4s;\n  overflow: scroll;\n\n  @keyframes fadein {\n  0% {\n    transform: scale(0.1);\n    transition: all 500ms ease-out,\n    transform-origin 1ms;\n  }\n  100% {\n    opacity: 1;\n    transform: scale(1);\n    }\n}\n"]);

  _templateObject2 = function () {
    return data;
  };

  return data;
}

function _templateObject() {
  const data = _taggedTemplateLiteral(["\n  display: none;\n  width: 100%;\n  height: 100%;\n  position: fixed;\n  z-index: 99999;\n  left: 0;\n  top: 0;\n  overflow: hidden;\n  background-color: rgb(0,0,0); /* Fallback color */\n  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */\n  justify-content: center;\n  align-items: center;\n"]);

  _templateObject = function () {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

import styled from 'styled-components';
import colors from "../../components/colors";
export const Container = styled.div.withConfig({
  displayName: "Container",
  componentId: "mmdoo9-0"
})(_templateObject());
export const ModalContent = styled.div.withConfig({
  displayName: "ModalContent",
  componentId: "mmdoo9-1"
})(_templateObject2());
export const ModalHeader = styled.div.withConfig({
  displayName: "ModalHeader",
  componentId: "mmdoo9-2"
})(_templateObject3());
export const ButtonBack = styled.button.withConfig({
  displayName: "ButtonBack",
  componentId: "mmdoo9-3"
})(_templateObject4());
export const ButtonClose = styled.button.withConfig({
  displayName: "ButtonClose",
  componentId: "mmdoo9-4"
})(_templateObject5());
export const ModalText = styled.p.withConfig({
  displayName: "ModalText",
  componentId: "mmdoo9-5"
})(_templateObject6(), props => props.color ? props.color : '#000');
export const Input = styled.input.withConfig({
  displayName: "Input",
  componentId: "mmdoo9-6"
})(_templateObject7());
export const Textarea = styled.textarea.withConfig({
  displayName: "Textarea",
  componentId: "mmdoo9-7"
})(_templateObject8());
export const ButtonNext = styled.button.withConfig({
  displayName: "ButtonNext",
  componentId: "mmdoo9-8"
})(_templateObject9(), colors.blue);
export const ButtonFinished = styled.button.withConfig({
  displayName: "ButtonFinished",
  componentId: "mmdoo9-9"
})(_templateObject10(), colors.green);