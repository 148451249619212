function _templateObject2() {
  const data = _taggedTemplateLiteral(["\n  width: 100%;\n  height: 100px;\n  display: flex;\n  align-items: flex-end;\n  padding-left: 5px;\n"]);

  _templateObject2 = function () {
    return data;
  };

  return data;
}

function _templateObject() {
  const data = _taggedTemplateLiteral(["\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: center;\n  ul {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: flex-start;\n  }\n  li {\n    width: 100%;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 10px;\n    border-top: 1px solid #bdbdbd;\n    border-bottom: 1px solid #bdbdbd;\n    font-weight: bold;\n  }\n"]);

  _templateObject = function () {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

import styled from 'styled-components';
export const Container = styled.div.withConfig({
  displayName: "Container",
  componentId: "oygr6r-0"
})(_templateObject());
export const NavContainer = styled.div.withConfig({
  displayName: "NavContainer",
  componentId: "oygr6r-1"
})(_templateObject2());