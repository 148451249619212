const prefix = '@packiot:';
const USER_NAME = "".concat(prefix, "username");
const SITE_INFO = "".concat(prefix, "site-info");
const LANGUAGE = "".concat(prefix, "language");
const USER_ROLES = "".concat(prefix, "roles");
const FIREBASE_TOKEN = "".concat(prefix, "token");
const ENTERPRISE = "".concat(prefix, "id-enterprise");
const EQUIPMENTS = "".concat(prefix, "equipments");
const END_TIME = "".concat(prefix, "endPoTime");
const START_TIME = "".concat(prefix, "startPoTime");
const JUSTIFY_TEXT = "".concat(prefix, "justification");
const ORDER_DATA = "".concat(prefix, "orderData");
const NEXT_ORDER = "".concat(prefix, "nextOrder");
const QUANTITY = "".concat(prefix, "quantity");
export const getUserName = () => localStorage.getItem(USER_NAME);
export const getSiteInfo = () => localStorage.getItem(SITE_INFO);
export const getLanguage = () => localStorage.getItem(LANGUAGE);
export const getRoles = () => localStorage.getItem(USER_ROLES);
export const getToken = () => localStorage.getItem(FIREBASE_TOKEN);
export const getEnterprise = () => localStorage.getItem(ENTERPRISE);
export const getEquipmentsList = () => localStorage.getItem(EQUIPMENTS);
export const getEndPoTime = () => localStorage.getItem(END_TIME);
export const getStartPoTime = () => localStorage.getItem(START_TIME);
export const getJustifyText = () => localStorage.getItem(JUSTIFY_TEXT);
export const getOrderData = () => localStorage.getItem(ORDER_DATA);
export const getNextOrder = () => localStorage.getItem(NEXT_ORDER);
export const getQuantity = () => localStorage.getItem(QUANTITY);
export const setInitialInfo = (name, roles, id) => {
  roles = JSON.stringify(roles);
  localStorage.setItem(USER_NAME, name);
  localStorage.setItem(USER_ROLES, roles);
  localStorage.setItem(ENTERPRISE, id);
};
export const setActiveSite = site => {
  localStorage.setItem(SITE_INFO, site);
};
export const setLanguage = lang => {
  let language = JSON.stringify(lang);
  localStorage.setItem(LANGUAGE, language);
};
export const setToken = token => {
  localStorage.setItem(FIREBASE_TOKEN, token);
};
export const setEquipmentsList = data => {
  data = JSON.stringify(data);
  localStorage.setItem(EQUIPMENTS, data);
};
export const setEndPoTime = endTime => {
  localStorage.setItem(END_TIME, endTime);
};
export const setStartPoTime = startTime => {
  localStorage.setItem(START_TIME, startTime);
};
export const setJustifyText = text => {
  localStorage.setItem(JUSTIFY_TEXT, text);
};
export const setOrderData = data => {
  data = JSON.stringify(data);
  localStorage.setItem(ORDER_DATA, data);
};
export const setNextOrder = data => {
  data = JSON.stringify(data);
  localStorage.setItem(NEXT_ORDER, data);
};
export const setQuantity = quantity => {
  localStorage.setItem(QUANTITY, quantity);
};
export const removeInfo = () => {
  localStorage.clear();
};