/* eslint-disable max-len */
import React, { useState, useRef, useEffect } from 'react';
import { ModalText, ButtonNext } from "../styles";
import { Form } from '@unform/web';
import { Spacer } from "../../../components";
import Select from "../../../components/Form/Select";
import SectionHeader from "../../../components/compositions/SectionHeader";
import { fixNumber } from "../../../utils/helpers";
import { setNextOrder, getLanguage } from "../../../utils/storage";
export default function NextOrder({
  next,
  nextStep
}) {
  const [order, setOrder] = useState(next[0]);
  const language = JSON.parse(getLanguage());
  const formRef = useRef(null);
  let orderOptions = next.map((item, index) => ({
    value: index,
    label: item.id_order === 0 ? language.noNextJob : "".concat(language.job, " ").concat(item.id_order, "\n      - ").concat(language.product, " ").concat(item.product.nm_product, " - ").concat(language.client, " ").concat(item.client.nm_client)
  }));
  useEffect(() => {
    setNextOrder(order);
  }, [order]);
  return React.createElement("div", {
    style: {
      padding: '50px'
    }
  }, React.createElement(Spacer, {
    isVertical: true,
    size: 20
  }), React.createElement(ModalText, null, " ", language.confirmNextJob, " "), React.createElement(Spacer, {
    isVertical: true,
    size: 30
  }), React.createElement(Form, {
    ref: formRef,
    onSubmit: () => {}
  }, React.createElement(Select, {
    placeholder: language.job,
    onChange: e => setOrder(next[e.value]),
    name: "next",
    options: orderOptions
  })), order.id_order !== 0 && React.createElement(React.Fragment, null, React.createElement(Spacer, {
    isVertical: true,
    size: 30
  }), React.createElement(ModalText, null, order.client.nm_client), React.createElement(Spacer, {
    isVertical: true,
    size: 10
  }), React.createElement(ModalText, null, order.product.nm_product), React.createElement(Spacer, {
    isVertical: true,
    size: 50
  }), React.createElement(SectionHeader, {
    icon: "ChartBars",
    title: language.quantity
  }), React.createElement(ModalText, null, fixNumber(order.production_programmed)), React.createElement("p", null, " ", language.jobSize)), React.createElement(Spacer, {
    isVertical: true,
    size: 30
  }), React.createElement(ButtonNext, {
    onClick: () => {
      nextStep(1);
    }
  }, language.next));
}