/* eslint-disable no-unused-vars */
import React from 'react';
import { Router } from '@reach/router';
import Home from "../Home";
import Events from "../Events";
import Lines from "../Lines";
import SiteManager from "../SiteManager";
import Orders from "../Orders";
import Machines from "../Machines";
import SetupScreen from "../SetupScreen";
import Sectors from "../Sectors"; //nested routes

import AddEvent from "../Events/AddEvent";
import JustifyEvent from "../Events/JustifyEvent";
import EditEvent from "../Events/EditEvent";

const Routes = props => React.createElement(Router, {
  style: {
    flex: 1,
    display: 'flex',
    overflow: 'hidden'
  }
}, React.createElement(SiteManager, {
  path: "/"
}), React.createElement(Home, {
  path: "/current/:idEquip"
}), React.createElement(SiteManager, {
  path: "/site"
}), React.createElement(Lines, {
  path: "/lines"
}), React.createElement(Sectors, {
  path: "/sectors"
}), React.createElement(Events, {
  path: "/events/:idEquip"
}), React.createElement(AddEvent, {
  path: "/events/:idEquip/add"
}), React.createElement(EditEvent, {
  path: "/events/:idEquip/edit"
}), React.createElement(JustifyEvent, {
  path: "/events/:idEquip/ack"
}), React.createElement(Orders, {
  path: "/orders"
}), React.createElement(Machines, {
  path: "/machines"
}), React.createElement(SetupScreen, {
  path: "/setup/:idEquip"
}));

export default Routes;