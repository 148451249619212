function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Formik, FieldArray } from 'formik';
const timeout = setTimeout; // Set initial focus to the first input field

function setFormInitialFocus(formElement) {
  const form = formElement;

  if (form) {
    const formElements = form.elements;

    for (let i = 0; i < formElements.length; i++) {
      if (formElements[i].nodeName !== 'BUTTON') {
        timeout(() => formElements[i].focus(), 100);
        break;
      }
    }
  }
}

export const FieldRepeater = FieldArray;
export const Form = (_ref) => {
  let {
    children,
    hasAutoFocus
  } = _ref,
      rest = _objectWithoutPropertiesLoose(_ref, ["children", "hasAutoFocus"]);

  const formRef = useRef();
  useEffect(() => {
    setInitialFocus();
    return () => clearTimeout(timeout);
  }, []);

  function setInitialFocus() {
    if (hasAutoFocus) setFormInitialFocus(formRef.current);
  }

  return React.createElement("form", _extends({}, rest, {
    ref: formRef
  }), children);
};
Form.defaultProps = {
  hasAutoFocus: false
};
Form.propTypes = {
  children: PropTypes.element.isRequired,
  hasAutoFocus: PropTypes.bool
};

const FormContainer = (_ref2) => {
  let {
    hasAutoFocus,
    onSubmit,
    render
  } = _ref2,
      rest = _objectWithoutPropertiesLoose(_ref2, ["hasAutoFocus", "onSubmit", "render"]);

  return React.createElement(Formik, _extends({}, rest, {
    onSubmit: onSubmit,
    render: renderProps => React.createElement(Form, {
      hasAutoFocus: hasAutoFocus,
      onReset: renderProps.handleReset,
      onSubmit: event => event.preventDefault()
    }, render(renderProps))
  }));
};

FormContainer.defaultProps = {
  hasAutoFocus: false,
  onSubmit: null,
  render: null
};
FormContainer.propTypes = {
  hasAutoFocus: PropTypes.bool,
  onSubmit: PropTypes.func,
  render: PropTypes.func
};
export default FormContainer;