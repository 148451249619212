function _templateObject2() {
  const data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding: 3px;\n  span {\n    font-weight: ", ";\n    font-size: 20px;\n  }\n  p {\n    font-size: 16px;\n  }\n"]);

  _templateObject2 = function () {
    return data;
  };

  return data;
}

function _templateObject() {
  const data = _taggedTemplateLiteral(["\n  width: 100%;\n  height: 100px;\n  display: flex;\n  justify-content: space-between;\n  flex-wrap: wrap;\n"]);

  _templateObject = function () {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';
import colors from "../../../components/colors";
import { ButtonFinished } from "../styles";
import moment from 'moment';
import SectionHeader from "../../../components/compositions/SectionHeader";
import PoLifecycle from "../../../components/compositions/PoLifecycle";
import { Title, Text, Group, Spacer, Grid, Row, Col } from "../../../components";
import { capitalize, fixNumber } from "../../../utils/helpers";
import { getLanguage, getUserName, getOrderData, getNextOrder, getQuantity, getEndPoTime, getStartPoTime } from "../../../utils/storage";
const Container = styled.div.withConfig({
  displayName: "Container",
  componentId: "sc-1pvsref-0"
})(_templateObject());
const Quantity = styled.div.withConfig({
  displayName: "Quantity",
  componentId: "sc-1pvsref-1"
})(_templateObject2(), props => props.weight ? props.weight : 'bold');
export default function EndingScreen({
  orderStart,
  downtime,
  handleConfirm
}) {
  let operator = getUserName();
  let order = JSON.parse(getOrderData());
  let nextOrder = JSON.parse(getNextOrder());
  let quantity = Number(getQuantity());
  let endTime = getEndPoTime();
  const language = JSON.parse(getLanguage());
  let scrap = order[0].gross_production - quantity;
  return React.createElement(React.Fragment, null, React.createElement("div", {
    style: {
      background: '#d0e6d4',
      color: colors.gray,
      paddingLeft: '50px',
      paddingRight: '50px'
    }
  }, React.createElement(Spacer, {
    isVertical: true,
    size: 60
  }), React.createElement("h1", {
    style: {
      color: colors.green,
      fontWeight: 'lighter'
    }
  }, " ", language.ended, " "), React.createElement(Grid, {
    fluid: true
  }, React.createElement(Row, {
    between: "xs"
  }, React.createElement(Col, {
    xs: 6
  }, React.createElement(Group, {
    isVerticalCentered: true,
    spaceSize: 10
  }, React.createElement(Title, {
    isLarge: true,
    isBold: true,
    hasNoMarginBottom: true
  }, language.job, " ", order[0].id_order)), React.createElement(Spacer, {
    isVertical: true,
    size: 5
  }), React.createElement(Text, {
    color: colors.gray
  }, " ", language.completed, " ")), React.createElement(Col, {
    xs: 6
  }, React.createElement("div", {
    style: {
      textAlign: 'right'
    }
  }, React.createElement(Text, {
    isMedium: true
  }, " ", order[0].nm_client, " "), React.createElement(Spacer, {
    isVertical: true,
    size: 5
  }), React.createElement(Text, {
    isMedium: true
  }, " ", order[0].nm_product, " ")))), React.createElement(Spacer, {
    isLarge: true,
    isVertical: true
  }), React.createElement(Row, null, React.createElement(Col, {
    xs: 12
  }, React.createElement(Group, {
    isVerticalCentered: true,
    spaceSize: 10
  }, React.createElement(Text, null, " ", language.operator, " ", React.createElement("b", null, capitalize(operator))))))), React.createElement(Spacer, {
    isVertical: true,
    size: 30
  }), React.createElement(SectionHeader, {
    icon: "ChartBars",
    title: language.quantity,
    color: colors.gray
  }), React.createElement(Container, null, React.createElement(Quantity, {
    weight: 500
  }, React.createElement("span", null, " ", fixNumber(order[0].production_programmed), " "), React.createElement("p", null, " ", language.jobSize, " ")), React.createElement(Quantity, {
    weight: 500
  }, React.createElement("span", null, " ", fixNumber(order[0].net_production, 2), " "), React.createElement("p", null, " ", language.counted, " ")), quantity != 0 ? React.createElement(Quantity, null, React.createElement("span", {
    style: {
      color: colors.green
    }
  }, " ", fixNumber(quantity), " "), React.createElement("p", {
    style: {
      color: colors.green
    }
  }, " ", language.produced, " ")) : '', React.createElement(Quantity, {
    weight: 500
  }, React.createElement("span", null, " ", fixNumber(scrap), " "), React.createElement("p", null, "  ", language.scrap, " "))), React.createElement(Spacer, {
    isVertical: true,
    size: 20
  }), React.createElement(SectionHeader, {
    icon: "Clock",
    title: language.time,
    color: colors.gray
  }), React.createElement(PoLifecycle, {
    language: language,
    start: orderStart,
    downtime: downtime,
    end: endTime
  }), React.createElement(Spacer, {
    isVertical: true,
    size: 20
  })), Number(nextOrder.id_order) !== 0 && React.createElement("div", {
    style: {
      paddingLeft: '50px',
      paddingRight: '50px'
    }
  }, React.createElement("h1", {
    style: {
      color: colors.green,
      fontWeight: 'lighter'
    }
  }, "Started"), React.createElement(Grid, {
    fluid: true
  }, React.createElement(Row, {
    between: "xs"
  }, React.createElement(Col, {
    xs: 6
  }, React.createElement(Group, {
    isVerticalCentered: true,
    spaceSize: 10
  }, React.createElement(Title, {
    isLarge: true,
    isBold: true,
    hasNoMarginBottom: true
  }, language.job, " ", nextOrder.id_order))), React.createElement(Col, {
    xs: 6
  }, React.createElement("div", {
    style: {
      textAlign: 'right'
    }
  }, React.createElement(Text, {
    isMedium: true
  }, " ", nextOrder.client.nm_client, " "), React.createElement(Spacer, {
    isVertical: true,
    size: 5
  }), React.createElement(Text, {
    isMedium: true
  }, " ", nextOrder.product.nm_product, " "))))), React.createElement(Container, null, React.createElement(Quantity, null, React.createElement("span", null, " ", fixNumber(nextOrder.production_programmed), " "), React.createElement("p", null, " ", language.jobSize, " ")), React.createElement(Quantity, null, React.createElement("span", null, " ", React.createElement(Text, {
    isBold: true
  }, " ", moment(endTime).format('MM/DD'), " ", React.createElement("small", null, "\u25CF"), " ", moment(endTime).format('HH:mm')), " "), React.createElement("p", null, " ", language.startTime, " ")))), React.createElement("div", {
    style: {
      paddingLeft: '50px',
      paddingRight: '50px'
    }
  }, React.createElement(ButtonFinished, {
    onClick: () => handleConfirm()
  }, language.confirm), React.createElement(Spacer, {
    isVertical: true,
    size: 20
  })));
}