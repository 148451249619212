function _templateObject() {
  const data = _taggedTemplateLiteral(["\n  width: 245px;\n  height: 100px;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: center;\n  border-bottom: 4px solid ", ";\n  padding: 20px;\n  cursor: pointer;\n"]);

  _templateObject = function () {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

import React from 'react';
import styled from 'styled-components';
import colors from "../../colors";
const TabContainer = styled.div.withConfig({
  displayName: "TabContainer",
  componentId: "sc-1pwpbdu-0"
})(_templateObject(), props => props.isActive ? colors.blue : 'transparent');
export default function NavigationTab({
  isActive,
  onClick,
  children
}) {
  return React.createElement(TabContainer, {
    isActive: isActive,
    onClick: onClick
  }, children);
}
;