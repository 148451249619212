function _templateObject2() {
  const data = _taggedTemplateLiteral(["\n  display: inline-block;\n  position: relative;\n  width: 80px;\n  height: 80px;\n  div {\n    position: absolute;\n    width: 16px;\n    height: 16px;\n    border-radius: 50%;\n    background: ", ";\n    animation: lds-grid 1.2s linear infinite;\n  }\n  div:nth-child(1) {\n  top: 8px;\n  left: 8px;\n  animation-delay: 0s;\n}\ndiv:nth-child(2) {\n  top: 8px;\n  left: 32px;\n  animation-delay: -0.4s;\n}\ndiv:nth-child(3) {\n  top: 8px;\n  left: 56px;\n  animation-delay: -0.8s;\n}\ndiv:nth-child(4) {\n  top: 32px;\n  left: 8px;\n  animation-delay: -0.4s;\n}\ndiv:nth-child(5) {\n  top: 32px;\n  left: 32px;\n  animation-delay: -0.8s;\n}\ndiv:nth-child(6) {\n  top: 32px;\n  left: 56px;\n  animation-delay: -1.2s;\n}\ndiv:nth-child(7) {\n  top: 56px;\n  left: 8px;\n  animation-delay: -0.8s;\n}\n div:nth-child(8) {\n  top: 56px;\n  left: 32px;\n  animation-delay: -1.2s;\n}\ndiv:nth-child(9) {\n  top: 56px;\n  left: 56px;\n  animation-delay: -1.6s;\n}\n@keyframes lds-grid {\n  0%, 100% {\n    opacity: 1;\n  }\n  50% {\n    opacity: 0.5;\n  }\n}\n\n"]);

  _templateObject2 = function () {
    return data;
  };

  return data;
}

function _templateObject() {
  const data = _taggedTemplateLiteral(["\n    width: 100%;\n    height: ", "px;\n    z-index: 99;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    overflow: auto;\n    background:  ", ";\n"]);

  _templateObject = function () {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

import React from 'react';
import styled from 'styled-components';
import colors from "../../colors";
const Container = styled.div.withConfig({
  displayName: "Container",
  componentId: "sc-16uztnl-0"
})(_templateObject(), props => props.size, props => props.white ? '#fff' : '#E2E2E7');
const Grid = styled.div.withConfig({
  displayName: "Grid",
  componentId: "sc-16uztnl-1"
})(_templateObject2(), colors.blueDark);
export default function LoadContainer(props) {
  const {
    size
  } = props;
  return React.createElement(Container, {
    size: size
  }, React.createElement(Grid, null, React.createElement("div", null), React.createElement("div", null), React.createElement("div", null), React.createElement("div", null), React.createElement("div", null), React.createElement("div", null), React.createElement("div", null), React.createElement("div", null), React.createElement("div", null)));
}