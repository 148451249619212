import React from 'react';
import { Container, ModalContent } from "./styles";
export default function ModalSetup(props) {
  const {
    children,
    visible,
    dialog
  } = props;
  return React.createElement(Container, {
    style: {
      display: visible ? 'flex' : 'none'
    }
  }, React.createElement(ModalContent, {
    dialog: dialog
  }, children));
}