function _templateObject() {
  const data = _taggedTemplateLiteral(["\nquery GetMachinesKY($id_equipment: bigint, $sector: String) {\n  equipment_types_relation(where: {_and: {equipment: {id_equipment: {_eq: $id_equipment}}}}, order_by: {position: asc}) {\n    equipment {\n      child_machines(where: {cd_sector: {_eq: $sector}}) {\n        cd_equipment\n        cd_sector\n        id_equipment\n      }\n    }\n  }\n}\n"]);

  _templateObject = function () {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

/* eslint-disable max-len */
import gql from 'graphql-tag';
export const GET_MACHINES_KY = gql(_templateObject());
export const GET_SOLVED_EVENTS_KY = "\nquery GetMachinesEventsKY($sector: String $id_equipment: Int, $id_enterprise: Int, $ts_start: timestamptz, $ts_end: timestamptz) {\n  piot_get_machine_events_timeline_ky(args: {in_cd_sector: $sector, in_id_enterprise: $id_enterprise, in_id_equipment: $id_equipment, time_begin: $ts_start, time_end: $ts_end}, order_by: {ts_event: desc}) {\n    event_type\n    id_equipment_reason\n    id_equipment_event\n    id_machine\n    cd_shift\n    id_order\n    stagetime\n    ts_end\n    txt_downtime_notes\n    cd_subcategory\n    change_over\n    downtimereason\n    id_subcategory\n    nm_subcategory\n    nm_downtime_reason\n    txt_downtime_reason\n    txt_subcategory\n    ts_event\n    cd_machine\n  }\n}\n";
export const GET_SOLVED_EVENTS = "\nquery GetSolvedEvents($id_equipment: Int, $id_enterprise: Int, $ts_start: timestamptz, $ts_end: timestamptz) {\n  piot_get_events_timeline(args: {in_id_enterprise: $id_enterprise, in_id_equipment: $id_equipment, time_begin: $ts_start, time_end: $ts_end}, order_by: {ts_event: desc}) {\n    event_type\n    id_equipment_reason\n    id_equipment_event\n    id_machine\n    cd_shift\n    id_order\n    stagetime\n    ts_end\n    txt_downtime_notes\n    cd_subcategory\n    change_over\n    downtimereason\n    id_subcategory\n    nm_subcategory\n    nm_downtime_reason\n    txt_downtime_reason\n    txt_subcategory\n    ts_event\n    equipment_type {\n      nm_equipment_type\n    }\n  }\n}\n";
export const GET_MACHINES_SOLVED_EVENTS = "\n  query GetMachinesSolvedEvents($id_equipment: Int, $id_enterprise: Int, $ts_start: timestamptz, $ts_end: timestamptz) {\n  piot_get_machine_events_timeline(args: {in_id_enterprise: $id_enterprise, in_id_equipment: $id_equipment, time_begin: $ts_start, time_end: $ts_end}, order_by: {ts_event: desc}) {\n    event_type\n    id_equipment_reason\n    id_equipment_event\n    id_machine\n    cd_shift\n    id_order\n    stagetime\n    ts_end\n    txt_downtime_notes\n    cd_subcategory\n    change_over\n    downtimereason\n    id_subcategory\n    nm_subcategory\n    nm_downtime_reason\n    txt_downtime_reason\n    txt_subcategory\n    ts_event\n    equipment_type {\n      nm_equipment_type\n    }\n  }\n}\n";