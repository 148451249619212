function _templateObject8() {
  const data = _taggedTemplateLiteral(["\n  position: absolute;\n  bottom: ", "px;\n  left:0;\n  z-index: 49;\n  width: 100%;\n  height: 1px;\n  border-bottom: 1px dashed ", ";\n\n"]);

  _templateObject8 = function () {
    return data;
  };

  return data;
}

function _templateObject7() {
  const data = _taggedTemplateLiteral(["\n  position: absolute;\n  top: -25px;\n"]);

  _templateObject7 = function () {
    return data;
  };

  return data;
}

function _templateObject6() {
  const data = _taggedTemplateLiteral(["\n  position: absolute;\n  bottom: -70px;\n"]);

  _templateObject6 = function () {
    return data;
  };

  return data;
}

function _templateObject5() {
  const data = _taggedTemplateLiteral(["\n  position: relative;\n  width: 115px;\n  height: ", "px;\n  background-color: ", ";\n"]);

  _templateObject5 = function () {
    return data;
  };

  return data;
}

function _templateObject4() {
  const data = _taggedTemplateLiteral(["", ""]);

  _templateObject4 = function () {
    return data;
  };

  return data;
}

function _templateObject3() {
  const data = _taggedTemplateLiteral(["\n  position: relative;\n  align-items: flex-end;\n  margin-bottom: 70px;\n\n"]);

  _templateObject3 = function () {
    return data;
  };

  return data;
}

function _templateObject2() {
  const data = _taggedTemplateLiteral(["\n  animation: 1s ", ";\n  color: ", ";\n  display: 'block';\n  font-size: 25px;\n  font-weight: ", ";\n  line-height: 1.2;\n  white-space: ", ";\n"]);

  _templateObject2 = function () {
    return data;
  };

  return data;
}

function _templateObject() {
  const data = _taggedTemplateLiteral(["", ""]);

  _templateObject = function () {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { pulse } from 'react-animations';
import { Text, Group, Fill } from "../..";
import colors from "../../colors";
import { fixNumber } from "../../../utils/helpers";
const pulseAnimation = keyframes(_templateObject(), pulse);
const PulseText = styled.div.withConfig({
  displayName: "PulseText",
  componentId: "sc-1jt72ow-0"
})(_templateObject2(), pulseAnimation, props => props.color, props => props.isBold ? 'bold' : 'initial', props => props.noWrap ? 'nowrap' : 'initial');
const Container = styled(Group).withConfig({
  displayName: "Container",
  componentId: "sc-1jt72ow-1"
})(_templateObject3());
const Bar = styled.div.withConfig({
  displayName: "Bar",
  componentId: "sc-1jt72ow-2"
})(_templateObject4(), ({
  size,
  color
}) => css(_templateObject5(), size, color || colors.grayLight));
const BarLabel = styled.div.withConfig({
  displayName: "BarLabel",
  componentId: "sc-1jt72ow-3"
})(_templateObject6());
const BarValue = styled(Text).withConfig({
  displayName: "BarValue",
  componentId: "sc-1jt72ow-4"
})(_templateObject7());
const OrderLine = styled.div.withConfig({
  displayName: "OrderLine",
  componentId: "sc-1jt72ow-5"
})(_templateObject8(), props => props.size, colors.gray);

const PoQuantity = ({
  data,
  quantity,
  language
}) => {
  let orderSize = quantity;
  let scrap = 0;

  if ((data === null || data === void 0 ? void 0 : data.scrap) >= 0) {
    scrap = data.scrap;
  } else {
    scrap = data.gross_production - data.net_production;
  }

  if (scrap < 0) scrap = 0;

  function calculateBarSize(num, maxValue) {
    let size = num * 100 / maxValue;
    if (size < 1 || isNaN(size)) size = 1;
    return size;
  }

  let maxValue = Math.max(orderSize, data.net_production, scrap);
  let orderSizeBar = calculateBarSize(orderSize, maxValue);
  let producedBar = calculateBarSize(data.net_production, maxValue);
  let scrapBar = calculateBarSize(scrap, maxValue);
  let formatedScrap = fixNumber(scrap);
  let totalProd = fixNumber(data.net_production);
  let percentScrap = 0;

  if ((data === null || data === void 0 ? void 0 : data.scrap_perc) >= 0) {
    percentScrap = data.scrap_perc * 100;
  } else {
    percentScrap = (data.gross_production - data.net_production) * 100 / data.net_production;
  }

  if (percentScrap > 100) percentScrap = 100;
  if (percentScrap < 0) percentScrap = 0;
  return React.createElement(Container, {
    isFullWidth: true
  }, React.createElement(OrderLine, {
    size: orderSizeBar
  }), React.createElement(Bar, {
    size: orderSizeBar
  }, React.createElement(BarLabel, null, React.createElement(PulseText, {
    isBold: true,
    noWrap: true
  }, fixNumber(orderSize)), React.createElement(Text, {
    noWrap: true
  }, " ", language.jobSize, " "))), React.createElement(Fill, null), React.createElement(Bar, {
    size: producedBar,
    color: colors.blueLighter
  }, React.createElement(BarLabel, null, React.createElement(PulseText, {
    isBold: true,
    color: colors.blue,
    noWrap: true
  }, totalProd), React.createElement(Text, {
    noWrap: true
  }, " ", language.produced, " "))), React.createElement(Fill, null), React.createElement(Bar, {
    size: scrapBar,
    color: colors.orangeLighter
  }, React.createElement(BarValue, {
    color: colors.orange
  }, percentScrap.toFixed(1) + '%'), React.createElement(BarLabel, null, React.createElement(PulseText, {
    isBold: true,
    color: colors.orange,
    noWrap: true
  }, formatedScrap), React.createElement(Text, {
    noWrap: true
  }, " ", language.scrap, " "))));
};

export default PoQuantity;