import React from 'react';
import { initFirebase } from "../services/firebase";
import { client } from "../services/graphqlConnection";
import { ApolloProvider } from '@apollo/react-hooks';
import { Router } from '@reach/router';
import MainContextProvider from "../contexts/MainContext";
import { GlobalStyles } from "../components/settings";
import { ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SessionManager from "../containers/SessionManager";
import MainContainer from "../containers/MainContainer";
import SignInFlow from "../containers/SignInFlow";
initFirebase();

const App = () => {
  const basename = document.querySelector('base') ? document.querySelector('base').getAttribute('href') : null;
  return React.createElement(SessionManager, null, React.createElement(ApolloProvider, {
    client: client
  }, React.createElement(GlobalStyles, null), React.createElement(ToastContainer, {
    limit: 1,
    transition: Zoom
  }), React.createElement(MainContextProvider, null, React.createElement(Router, {
    basepath: basename
  }, React.createElement(SessionManager, {
    path: "/"
  }, React.createElement(MainContainer, {
    path: "/*"
  }), React.createElement(SignInFlow, {
    path: "auth/*"
  }))))));
};

let exportedApp = App;
export default exportedApp;