function _templateObject3() {
  const data = _taggedTemplateLiteral(["width: ", "px;"]);

  _templateObject3 = function () {
    return data;
  };

  return data;
}

function _templateObject2() {
  const data = _taggedTemplateLiteral(["height: ", "px;"]);

  _templateObject2 = function () {
    return data;
  };

  return data;
}

function _templateObject() {
  const data = _taggedTemplateLiteral(["\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-shrink: 0;\n  justify-content: center;\n  align-items: center;\n  ", "\n"]);

  _templateObject = function () {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
const Spacer = styled.div.withConfig({
  displayName: "Spacer",
  componentId: "sc-1wfs2gx-0"
})(_templateObject(), ({
  isLarge,
  isMedium,
  isSmall,
  isVertical,
  size
}) => {
  if (isSmall) size = 10;
  if (isMedium) size = 20;
  if (isLarge) size = 30;
  return isVertical ? css(_templateObject2(), size) : css(_templateObject3(), size);
});
Spacer.defaultProps = {
  size: 5
};
Spacer.propTypes = {
  isLarge: PropTypes.bool,
  isMedium: PropTypes.bool,
  isSmall: PropTypes.bool,
  isVertical: PropTypes.bool,
  size: PropTypes.number
};
export default Spacer;