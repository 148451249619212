function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React, { useRef, useEffect } from 'react';
import { useField } from '@unform/core';
export default function TextArea(_ref) {
  let {
    name
  } = _ref,
      rest = _objectWithoutPropertiesLoose(_ref, ["name"]);

  const textRef = useRef(null);
  const {
    fieldName,
    defaultValue,
    registerField,
    error
  } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: textRef.current,
      path: 'value'
    });
  }, [fieldName, registerField]);
  return React.createElement("textarea", _extends({
    className: "text-area",
    ref: textRef,
    defaultValue: defaultValue
  }, rest));
}