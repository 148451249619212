import React from 'react';
import { Text, Group, Fill } from "../..";
import moment from 'moment';
import { convertToDaysHoursNoSeconds, getPercentHour } from "../../../utils/helpers";

const PoLifecycle = ({
  start,
  downtime,
  end,
  language
}) => {
  let startDate = '';
  let startTime = '';
  let endDate = '';
  let endTime = '';
  let elapsedTime = '';
  let percentDowntime = '0';

  if (start != null) {
    startDate = moment(start).format('MM/DD');
    startTime = moment(start).format('HH:mm');
    let value = moment().diff(start, 'seconds');
    elapsedTime = convertToDaysHoursNoSeconds(value, 'seconds');
    percentDowntime = getPercentHour(value, downtime);
  }

  let totalDowntime = convertToDaysHoursNoSeconds(downtime, 'minutes');

  if (end) {
    endDate = moment(end).format('MM/DD');
    endTime = moment(end).format('HH:mm');
  }

  return React.createElement(Group, {
    isFullWidth: true,
    isWrapped: true
  }, React.createElement("div", null, React.createElement(Text, {
    isBold: true
  }, " ", startDate, " ", React.createElement("small", null, "\u25CF"), " ", startTime), React.createElement(Text, null, " ", language.startTime, " ")), React.createElement(Fill, null), React.createElement("div", null, React.createElement(Text, {
    isBold: true
  }, elapsedTime), React.createElement(Text, null, " ", language.elapsedTime, " ")), React.createElement(Fill, null), React.createElement("div", null, React.createElement(Text, {
    isBold: true
  }, totalDowntime, " (", percentDowntime, "%)"), React.createElement(Text, null, " ", language.downtime, " ")), React.createElement(Fill, null), end ? React.createElement("div", null, React.createElement(Text, {
    isBold: true
  }, endDate, " ", React.createElement("small", null, "\u25CF"), " ", endTime), React.createElement(Text, null, " ", language.endTime, " ")) : '');
};

export default PoLifecycle;