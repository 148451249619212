const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px'
};
export const DEVICE = {
  mobileS: "(min-width: ".concat(size.mobileS, ")"),
  mobileM: "(-width: ".concat(size.mobileM, ")"),
  mobileL: "(max-width: ".concat(size.mobileL, ")"),
  tablet: "(min-width: ".concat(size.tablet, ")"),
  laptop: "(min-width: ".concat(size.laptop, ")"),
  laptopL: "(min-width: ".concat(size.laptopL, ")"),
  desktop: "(min-width: ".concat(size.desktop, ")"),
  desktopL: "(min-width: ".concat(size.desktop, ")")
};