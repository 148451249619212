/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { ModalText, Input, ButtonNext } from "../styles";
import { Title, Text, Group, Spacer, Grid, Row, Col } from "../../../components";
import SectionHeader from "../../../components/compositions/SectionHeader";
import PoLifecycle from "../../../components/compositions/PoLifecycle";
import PoQuantity from "../../../components/compositions/PoQuantity";
import colors from "../../../components/colors";
import { capitalize } from "../../../utils/helpers";
import moment from 'moment';
import { setQuantity, getEndPoTime, getUserName, getOrderData, getLanguage, setEndPoTime } from "../../../utils/storage";
export default function Setup({
  productionData,
  orderSize,
  orderStart,
  downtime,
  nextStep
}) {
  const [quantity, setQuantityProduced] = useState(productionData.net_production);
  const [startDate, setStartDate] = useState(new Date());
  useEffect(() => {
    setEndPoTime(moment().format());
  }, []);
  useEffect(() => {
    setQuantity(quantity);
  }, [quantity]);
  const endTime = getEndPoTime();
  let operator = getUserName();
  let order = JSON.parse(getOrderData());
  const language = JSON.parse(getLanguage());
  return React.createElement("div", {
    style: {
      padding: '50px'
    }
  }, React.createElement(Spacer, {
    isVertical: true,
    size: 20
  }), React.createElement(ModalText, null, " ", language.confirmJobInfo, " "), React.createElement(Spacer, {
    isVertical: true,
    size: 20
  }), React.createElement(Grid, {
    fluid: true
  }, React.createElement(Row, {
    between: "xs"
  }, React.createElement(Col, {
    xs: 6
  }, React.createElement(Group, {
    isVerticalCentered: true,
    spaceSize: 10
  }, React.createElement(Title, {
    isLarge: true,
    isBold: true,
    hasNoMarginBottom: true
  }, language.job, " ", order[0].id_order)), React.createElement(Spacer, {
    isVertical: true,
    size: 5
  }), React.createElement(Text, {
    color: colors.gray
  }, " ", language.completed, " ")), React.createElement(Col, {
    xs: 6
  }, React.createElement("div", {
    style: {
      textAlign: 'right'
    }
  }, React.createElement(Text, {
    isMedium: true
  }, " ", order[0].nm_client, " "), React.createElement(Spacer, {
    isVertical: true,
    size: 5
  }), React.createElement(Text, {
    isMedium: true
  }, " ", order[0].nm_product, " ")))), React.createElement(Spacer, {
    isLarge: true,
    isVertical: true
  }), React.createElement(Row, null, React.createElement(Col, {
    xs: 12
  }, React.createElement(Group, {
    isVerticalCentered: true,
    spaceSize: 10
  }, React.createElement(Text, null, " ", language.operator, " ", React.createElement("b", null, capitalize(operator))))))), React.createElement(Spacer, {
    isVertical: true,
    size: 20
  }), React.createElement(SectionHeader, {
    icon: "ChartBars",
    title: language.quantity
  }), React.createElement(PoQuantity, {
    data: productionData,
    quantity: orderSize,
    language: language
  }), React.createElement(Spacer, {
    isVertical: true,
    size: 40
  }), React.createElement(ModalText, null, " ", language.differentQuantity, " "), React.createElement(Input, {
    value: quantity,
    onChange: e => setQuantityProduced(e.target.value)
  }), React.createElement("p", null, " ", language.realQuantity, " "), React.createElement(Spacer, {
    isVertical: true,
    size: 40
  }), React.createElement("div", {
    className: "date-container"
  }, React.createElement(Text, null, language.endTime), React.createElement("input", {
    className: "date-input",
    onChange: e => {
      setStartDate(moment(e.target.value).format());
      setEndPoTime(moment(e.target.value).format());
    },
    type: "datetime-local",
    placeholder: moment(startDate).format('YYYY-MM-DDTHH:mm'),
    defaultValue: moment(startDate).format('YYYY-MM-DDTHH:mm'),
    min: moment().subtract(1, 'day').format('YYYY-MM-DDTHH:mm'),
    max: moment().format('YYYY-MM-DDTHH:mm')
  })), React.createElement(SectionHeader, {
    icon: "Clock",
    title: language.time
  }), React.createElement(PoLifecycle, {
    language: language,
    start: orderStart,
    downtime: downtime,
    end: endTime
  }), React.createElement(Spacer, {
    size: 30,
    isVertical: true
  }), React.createElement(ButtonNext, {
    onClick: () => {
      //let percent = 0;

      /* try {
        if (Number(quantity) > Number(orderSize)) {
          percent = ((Number(quantity) - Number(orderSize)) / Number(orderSize)) * 100;
          if (percent > 5) {
            nextStep(1);    
          } else {
            nextStep(2);    
          }
        } else {
          percent = ((Number(orderSize) - Number(quantity)) * 100) / Number(orderSize);
          if (percent > 5) {
            nextStep(1);    
          } else {
            nextStep(2);    
          }
        }
        
      } catch (error) {
        console.error(error);
      }*/
      nextStep(1);
    }
  }, language.next));
}