function _templateObject() {
  const data = _taggedTemplateLiteral(["\n  position: fixed;\n  z-index: 80;\n  bottom: 10px;\n  left: 0;\n  width: 90%;\n  margin-left: 5%;\n  background-color: ", ";\n"]);

  _templateObject = function () {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

import React from 'react';
import styled, { css } from 'styled-components';
import { Box, Group, Text, Fill, Icon, Button } from "../..";
import { InfoBadge } from "../../assets/icons";
import colors, { convertHexToRGB } from "../../colors";
import { capitalize } from "../../../utils/helpers";
const CustomBox = styled(Box).withConfig({
  displayName: "CustomBox",
  componentId: "sc-1qfklbv-0"
})(_templateObject(), convertHexToRGB(colors.blueLight, .94));

const AlertBox = ({
  number,
  onClick,
  language
}) => {
  return React.createElement(CustomBox, {
    borderRadius: 10
  }, React.createElement(Group, {
    isVerticalCentered: true,
    padding: 5,
    spaceSize: 5
  }, React.createElement(Icon, {
    color: colors.white
  }, React.createElement(InfoBadge, null)), React.createElement(Text, {
    color: colors.white
  }, number, " ", capitalize(language.pendingEvents)), React.createElement(Fill, null), React.createElement(Button, {
    isSmall: true,
    onClick: onClick
  }, language.ok)));
};

AlertBox.propTypes = {};
export default AlertBox;