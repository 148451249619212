import React, { useState } from 'react';
import { navigate } from '@reach/router';
import { MdClear, MdArrowBack } from 'react-icons/md';
import { useMutation } from '@apollo/react-hooks';
import { SET_SETUP, SET_SETUP_NO_NEXT } from "./mutations";
import { getOrderData, getEndPoTime, getJustifyText, getNextOrder, getQuantity, getLanguage } from "../../utils/storage";
import { NotificationError, NotificationSuccess } from "../../utils/toast";
import ModalSetup from "./Modal";
import { ModalHeader, ButtonClose, ButtonBack } from "./styles.js";
import Setup from "./modalScreens/Setup"; //import ConfirmScreen from './modalScreens/ConfirmScreen';

import NextOrder from "./modalScreens/NextOrder";
import EndingScreen from "./modalScreens/EndingScreen";
import { Loading } from "../../components";
export default function SetupScreen(props) {
  const {
    idEquip
  } = props;
  const [page, setPage] = useState(0);
  const language = JSON.parse(getLanguage());
  const [setSetup, {
    data: setSetupData,
    loading: setSetupLoading
  }] = useMutation(SET_SETUP, {
    onError: () => NotificationError(language.errorOccurred)
  });
  const [setSetupNoNext, {
    data: setSetupNoNextData,
    loading: setSetupNoNextLoading
  }] = useMutation(SET_SETUP_NO_NEXT, {
    onError: () => NotificationError(language.errorOccurred)
  });
  let data = JSON.parse(localStorage.getItem('@packiot:setupData'));
  let orders = JSON.parse(localStorage.getItem('@packiot:orders'));
  orders.push({
    id_order: 0,
    id_production_order: 0,
    client: {
      nm_client: ''
    },
    product: {
      nm_product: '',
      productfamily: {
        nm_productfamily: ''
      }
    },
    production_programmed: ''
  });

  function handleConfirm() {
    let old_order = JSON.parse(getOrderData());
    let new_order = JSON.parse(getNextOrder());
    let production_final = getQuantity();
    let ts_end = getEndPoTime();
    let txt_order_notes = getJustifyText();

    if (Number(new_order.id_order) == 0) {
      setSetupNoNext({
        variables: {
          id_old_order: old_order[0].id_production_order,
          ts_end,
          production_real: old_order[0].net_production,
          gross: old_order[0].gross_production,
          production_final,
          txt_order_notes
        }
      });
    } else {
      setSetup({
        variables: {
          id_old_order: old_order[0].id_production_order,
          ts_end,
          id_new_order: new_order.id_production_order,
          ts_start: ts_end,
          production_real: old_order[0].net_production,
          production_final,
          gross: old_order[0].gross_production,
          txt_order_notes
        }
      });
    }
  }

  function nextStep(step) {
    setPage(page + step);
  }

  if (!data) return React.createElement("div", null, "No data!");
  let modalScreens = [React.createElement(Setup, {
    key: 0,
    productionData: data.productionData,
    orderSize: data.orderSize,
    orderStart: data.orderStart,
    downtime: data.downtimeElapsedTime,
    nextStep: nextStep
  }), //(<ConfirmScreen key={1} orderSize={data.orderSize} nextStep={nextStep} />),
  React.createElement(NextOrder, {
    key: 1,
    next: orders,
    nextStep: nextStep
  }), React.createElement(EndingScreen, {
    key: 2,
    orderStart: data.orderStart,
    downtime: data.downtimeElapsedTime,
    handleConfirm: handleConfirm
  })];

  if (setSetupData || setSetupNoNextData) {
    NotificationSuccess(language.successfulOperation);
    navigate("".concat(process.env.baseURL, "current/").concat(idEquip));
  }

  if (setSetupNoNextLoading || setSetupLoading) return React.createElement(Loading, null);
  return React.createElement(ModalSetup, {
    visible: true
  }, React.createElement(ButtonBack, {
    onClick: () => {
      if (page > 0) {
        setPage(page - 1);
      } else {
        navigate("".concat(process.env.baseURL, "events/").concat(idEquip));
      }
    }
  }, React.createElement(MdArrowBack, {
    size: 16
  })), React.createElement(ButtonClose, {
    onClick: () => {
      navigate("".concat(process.env.baseURL, "events/").concat(idEquip));
    }
  }, React.createElement(MdClear, {
    size: 16
  })), React.createElement(ModalHeader, null, " ", language.setup, " "), React.createElement("div", {
    style: {
      width: '100%',
      height: '100%',
      padding: 0
    }
  }, modalScreens[page]));
}