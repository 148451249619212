function _templateObject() {
  const data = _taggedTemplateLiteral(["\nmutation SetNewPo($id_new_order: bigint!, $ts_start: timestamptz) {\n  update_production_orders(where: {id_production_order: {_eq: $id_new_order}}, _set: {ts_start: $ts_start, ts_end: null, status: 1}) {\n    returning {\n      id_production_order\n    }\n    affected_rows\n  }\n}"]);

  _templateObject = function () {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

/* eslint-disable max-len */
import gql from 'graphql-tag';
export const SET_NEW_PO = gql(_templateObject());