import React, { createContext, useReducer } from 'react';
import { mainReducer } from "../store/reducer";
export const MainContext = createContext();

const MainContextProvider = ({
  children
}) => {
  const [state, dispatch] = useReducer(mainReducer);
  return React.createElement(MainContext.Provider, {
    value: {
      state,
      dispatch
    }
  }, children);
};

export default MainContextProvider;