function _templateObject3() {
  const data = _taggedTemplateLiteral(["\n  mutation UpdateManualEvent($id_enterprise: Int!, $id_equipment_event_man: Int!, $id_equipment_reason: Int, $ts_start: timestamptz, $ts_end: timestamptz, $reason: bigint, $id_sub: bigint, $txt: String) {\n  update_equipment_events_man(where: {id_enterprise: {_eq: $id_enterprise}, _and: {id_equipment_event_man: {_eq: $id_equipment_event_man}}}, _set: {downtime_reason: $reason, id_equipment_reason: $id_equipment_reason, id_subcategory: $id_sub, ts_event_end: $ts_end, ts_event_start: $ts_start, txt_downtime_notes: $txt}) {\n    affected_rows\n  }\n}\n\n"]);

  _templateObject3 = function () {
    return data;
  };

  return data;
}

function _templateObject2() {
  const data = _taggedTemplateLiteral(["\n  mutation SetManualEvent($id_enterprise: Int!, $id_equipment_event: Int!, $id_equipment_reason: Int, $ts_start: timestamptz, $ts_end: timestamptz, $reason: bigint, $id_sub: bigint, $txt: String) {\n  insert_equipment_events_man(objects: {id_enterprise: $id_enterprise, id_equipment_event: $id_equipment_event, id_equipment_reason: $id_equipment_reason, ts_event_start: $ts_start, txt_downtime_notes: $txt, downtime_reason: $reason, id_subcategory: $id_sub, ts_event_end: $ts_end}) {\n    affected_rows\n  }\n}\n\n"]);

  _templateObject2 = function () {
    return data;
  };

  return data;
}

function _templateObject() {
  const data = _taggedTemplateLiteral(["\nmutation SetAck($id_equipment_event: bigint!, $downtimereason: Int, $id_equipment_reason: Int, $txt: String, $id_sub: Int ) {\n  update_equipmentevents(where: {id_equipment_event: {_eq: $id_equipment_event}}, _set: {downtimereason: $downtimereason, id_equipment_reason: $id_equipment_reason, txt_downtime_notes: $txt, id_subcategory: $id_sub}) {\n    affected_rows\n  }\n}\n"]);

  _templateObject = function () {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

/* eslint-disable max-len */
import gql from 'graphql-tag';
export const SET_ACK = gql(_templateObject());
export const SET_MANUAL_EVENT = gql(_templateObject2());
export const UPDATE_MANUAL_EVENT = gql(_templateObject3());