import React from 'react';
import PropTypes from 'prop-types';
import { Title, Icon, Group } from "../..";
import * as icons from "../../assets/icons";
import colors from "../../colors";

const SectionHeader = ({
  icon,
  title,
  color
}) => {
  const IconElement = icons[icon];
  return React.createElement(Group, {
    spaceSize: 15,
    isVerticalCentered: true,
    style: {
      marginBottom: 30
    }
  }, React.createElement(Icon, {
    color: colors.gray,
    size: 20
  }, icon && React.createElement(IconElement, null)), React.createElement(Title, {
    color: color ? color : colors.grayLight,
    hasNoMarginBottom: true,
    isBold: true
  }, title.toUpperCase()));
};

SectionHeader.defaultProps = {
  icon: 'Times'
};
SectionHeader.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string.isRequired
};
export default SectionHeader;