function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React, { useState, useEffect } from 'react';
import { Modal } from "./..";

const useModal = config => {
  const _ref = config || {},
        {
    defaultState
  } = _ref,
        rest = _objectWithoutPropertiesLoose(_ref, ["defaultState"]);

  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    if (defaultState !== undefined) setIsOpen(defaultState);
    return () => setIsOpen(false);
  }, []);

  const toggle = state => {
    if (typeof state !== 'boolean') state = !isOpen;
    setIsOpen(state);
  };

  const render = content => isOpen && React.createElement(Modal, _extends({
    isOpen: true,
    onClickToClose: () => setIsOpen(false)
  }, rest), content);

  return [render, toggle];
};

export default useModal;