function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React, { useRef, useEffect } from 'react';
import ReactSelect from 'react-select';
import { useField } from '@unform/core';
export default function Select(_ref) {
  let {
    name,
    placeholder
  } = _ref,
      rest = _objectWithoutPropertiesLoose(_ref, ["name", "placeholder"]);

  const styles = {
    control: styles => Object.assign({}, styles, {
      width: '100%',
      marginTop: 10
    })
  };
  const selectRef = useRef(null);
  const {
    fieldName,
    defaultValue,
    registerField,
    error
  } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: 'state.value',
      getValue: ref => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }

          return ref.state.value.map(option => option.value);
        } else {
          if (!ref.state.value) {
            return '';
          }

          return ref.state.value.value;
        }
      }
    });
  }, [fieldName, registerField, rest.isMulti]);
  return React.createElement(ReactSelect, _extends({
    styles: styles,
    defaultValue: defaultValue,
    ref: selectRef,
    classNamePrefix: "react-select",
    placeholder: placeholder
  }, rest));
}
;