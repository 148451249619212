const INITIAL_STATE = {
  pendingEvents: [],
  currentOrder: [],
  machinesOptions: [],
  currentEquipment: [],
  equipmentType: {
    isSector: false,
    isMachine: false
  }
};
export const mainReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case 'PENDING_EVENTS':
      state = Object.assign({}, state, {}, {
        pendingEvents: action.payload
      });
      break;

    case 'CURRENT_ORDER':
      state = Object.assign({}, state, {}, {
        currentOrder: action.payload
      });
      break;

    case 'MACHINES_OPTIONS':
      state = Object.assign({}, state, {}, {
        machinesOptions: action.payload
      });
      break;

    case 'CURRENT_EQUIPMENT':
      state = Object.assign({}, state, {}, {
        currentEquipment: action.payload
      });
      break;

    case 'EQUIPMENT_TYPE':
      state = Object.assign({}, state, {}, {
        equipmentType: action.payload
      });
      break;

    default:
      break;
  }

  return state;
};