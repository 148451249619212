function _templateObject() {
  const data = _taggedTemplateLiteral(["\n  width: 100%;\n  padding: 30px;\n  background-color: ", ";\n  overflow: auto;\n"]);

  _templateObject = function () {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import colors from "../../colors";
const TabContainer = styled.div.withConfig({
  displayName: "TabContainer",
  componentId: "bo9wq-0"
})(_templateObject(), colors.grayLighter);
TabContainer.propTypes = {};
export default TabContainer;