/* eslint-disable jsx-a11y/label-has-for */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';
import { FaEnvelope } from 'react-icons/fa';
import { auth } from "../../../services/firebase";
import { FormContainer } from "../../../components";
import { Container } from "../styles";

const Recovery = () => {
  const [feedback, setFeedback] = useState('');
  return React.createElement(Container, null, React.createElement("div", {
    className: "form-container"
  }, "Recovery", React.createElement(FormContainer, {
    initialValues: {
      email: ''
    },
    onSubmit: ({
      email
    }) => {
      auth().sendPasswordResetEmail(email).then(() => {
        setFeedback('Email sent!');
      }).catch(({
        message
      }) => {
        setFeedback(message);
      });
    },
    render: ({
      values,
      handleChange,
      handleSubmit,
      isSubmitting
    }) => React.createElement(React.Fragment, null, feedback, React.createElement("br", null), React.createElement("div", {
      className: "input-login"
    }, React.createElement("label", {
      className: "label"
    }, "EMAIL"), React.createElement("span", {
      className: "icon-form"
    }, " ", React.createElement(FaEnvelope, {
      size: 14,
      color: "#dbdbdb"
    }), " "), React.createElement("input", {
      name: "email",
      disabled: isSubmitting,
      value: values.email,
      onChange: handleChange,
      style: {
        border: '1px solid'
      }
    })), React.createElement("button", {
      className: "login-btn",
      onClick: handleSubmit
    }, "Request"))
  }), React.createElement("br", null), React.createElement("button", {
    onClick: () => navigate("".concat(process.env.baseURL, "auth"))
  }, "Sign In")));
};

Recovery.propTypes = {};
export default Recovery;