function _templateObject12() {
  const data = _taggedTemplateLiteral(["\n  border-bottom-left-radius: 4px;\n  border-bottom-right-radius: 4px;\n  border-top: 1px solid #CCC;\n  display: flex;\n  justify-content: flex-end;\n  padding: 20px;\n  width: 100%;\n"]);

  _templateObject12 = function () {
    return data;
  };

  return data;
}

function _templateObject11() {
  const data = _taggedTemplateLiteral(["\n  flex: 1;\n  max-height: 100%;\n  overflow-y: auto;\n  padding: 20px;\n"]);

  _templateObject11 = function () {
    return data;
  };

  return data;
}

function _templateObject10() {
  const data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n"]);

  _templateObject10 = function () {
    return data;
  };

  return data;
}

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _templateObject9() {
  const data = _taggedTemplateLiteral(["\n  animation: ", " .3s ease-in-out;\n  background-color: ", ";\n  display: block;\n  height: 100%;\n  left: 0;\n  opacity: 0;\n  position: absolute;\n  top: 0;\n  width: 100%;\n  z-index: -1;\n"]);

  _templateObject9 = function () {
    return data;
  };

  return data;
}

function _templateObject8() {
  const data = _taggedTemplateLiteral(["\n  flex: 1;\n  overflow: hidden;\n  padding: 15px;\n"]);

  _templateObject8 = function () {
    return data;
  };

  return data;
}

function _templateObject7() {
  const data = _taggedTemplateLiteral(["\n  background-color: ", ";\n  border-radius: ", ";;\n  display: flex;\n  flex-direction: column;\n  opacity: 0;\n  position: absolute;\n  width: ", ";\n  height: ", ";\n  z-index: 100;\n  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);\n"]);

  _templateObject7 = function () {
    return data;
  };

  return data;
}

function _templateObject6() {
  const data = _taggedTemplateLiteral(["", ""]);

  _templateObject6 = function () {
    return data;
  };

  return data;
}

function _templateObject5() {
  const data = _taggedTemplateLiteral(["\n    height: 0;\n    opacity: 0;\n    width: 0;\n  "]);

  _templateObject5 = function () {
    return data;
  };

  return data;
}

function _templateObject4() {
  const data = _taggedTemplateLiteral(["\n    height: 100%;\n    opacity: 1;\n    width: 100%;\n    ", " {\n      opacity: 1;\n    }\n    ", " {\n      animation: ", " ", " ease-in-out;\n      opacity: 1;\n    }\n  "]);

  _templateObject4 = function () {
    return data;
  };

  return data;
}

function _templateObject3() {
  const data = _taggedTemplateLiteral(["\n  align-items: center;\n  display: flex;\n  justify-content: center;\n  left: 0;\n  overflow: hidden;\n  position: fixed;\n  top: 0;\n  z-index: 100;\n  ", "\n"]);

  _templateObject3 = function () {
    return data;
  };

  return data;
}

function _templateObject2() {
  const data = _taggedTemplateLiteral(["\n  0% { opacity: .1; }\n  100% { opacity: .99; }\n"]);

  _templateObject2 = function () {
    return data;
  };

  return data;
}

function _templateObject() {
  const data = _taggedTemplateLiteral(["\n  0% {\n    opacity: .1;\n    transform-origin: center;\n    transform: translateY(2%);\n  }\n  100% {\n    opacity: .99;\n    transform-origin: center;\n    transform: translateY(0);\n  }\n"]);

  _templateObject = function () {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

/* eslint-disable react/prop-types */
import React from 'react';
import ReactDOM from 'react-dom';
import styled, { css, keyframes } from 'styled-components';
import colors, { convertHexToRGB } from "../../colors";
const slideUpSoft = keyframes(_templateObject());
const fadeIn = keyframes(_templateObject2());
const Wrapper = styled.div.withConfig({
  displayName: "Wrapper",
  componentId: "sc-1yvqut5-0"
})(_templateObject3(), ({
  isOpen,
  isCover
}) => isOpen ? css(_templateObject4(), Background, Content, slideUpSoft, isCover ? '.3s' : '.1s') : css(_templateObject5()));
const Content = styled.div.withConfig({
  displayName: "Content",
  componentId: "sc-1yvqut5-1"
})(_templateObject6(), ({
  isCover
}) => css(_templateObject7(), colors.white, isCover ? '0' : '10px', isCover ? '100%' : 'auto', isCover ? '100%' : 'auto'));
const Body = styled.div.withConfig({
  displayName: "Body",
  componentId: "sc-1yvqut5-2"
})(_templateObject8());
const Background = styled.div.withConfig({
  displayName: "Background",
  componentId: "sc-1yvqut5-3"
})(_templateObject9(), fadeIn, props => props.isBlank ? 'rgba(255, 255, 255, 0.97)' : convertHexToRGB(colors.black, .6));

const ModalBody = ({
  isLightbox,
  isCover,
  isOpen,
  children,
  disableClose,
  onClickToClose,
  isBlank
}) => React.createElement(Wrapper, {
  isOpen: isOpen,
  isCover: isCover
}, isLightbox ? children : React.createElement(Content, {
  isCover: isCover
}, React.createElement(Body, null, children)), React.createElement(Background, {
  isBlank: isBlank,
  onClick: !disableClose ? onClickToClose : null
}));

const body = document.body;

class Portal extends React.PureComponent {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "element", document.createElement('div'));
  }

  componentDidMount() {
    body.appendChild(this.element);
  }

  componentWillUnmount() {
    body.removeChild(this.element);
  }

  render() {
    // eslint-disable-next-line react/destructuring-assignment
    return ReactDOM.createPortal(this.props.children, this.element);
  }

}

;

const Modal = props => React.createElement(Portal, null, React.createElement(ModalBody, props));

Modal.Wrapper = styled.div.withConfig({
  displayName: "Wrapper",
  componentId: "sc-1yvqut5-4"
})(_templateObject10());
Modal.Content = styled.div.withConfig({
  displayName: "Content",
  componentId: "sc-1yvqut5-5"
})(_templateObject11());
Modal.Footer = styled.div.withConfig({
  displayName: "Footer",
  componentId: "sc-1yvqut5-6"
})(_templateObject12());
export default Modal;